import React from "react";
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton} from "@mui/material";
import {NotificationH1Style, NotificationH2Style} from "src/utils/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";


const ConfirmDeleteShiftModal = ({open, onClose, onConfirm, text, children}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <IconButton
        onClick={onClose}
        variant={"outlined"}
        sx={{
          position: 'absolute',
          right: '0',
          top: '0',
        }}
      >
        <HighlightOffIcon fontSize="small" />
      </IconButton>
      <DialogTitle sx={NotificationH1Style}>
        DELETE SHIFT
      </DialogTitle>
      <DialogContent>
        <div>
          {children}
        </div>
        <DialogContentText
          style={{
            // ...NotificationH2Style,
            fontSize: '16px',
            whiteSpace: 'pre-line',
            marginBottom: '20px',
          }}
        >
          Are you sure you want to delete this shift? This action cannot be undone.
          {text ? <div style={{marginTop: '20px', fontWeight: 700}}>{text}</div> : ''}
        </DialogContentText>
      </DialogContent>
      <div style={{padding: '0 20px 20px 20px',}}>
        <Button
          fullWidth
          variant="contained"
          onClick={onConfirm}
        >DELETE IT!</Button>
      </div>
    </Dialog>
  )
}

export default ConfirmDeleteShiftModal;