import React, {useEffect, useState, useCallback} from 'react';
import BookShiftForm from "../BookShiftForm";
import Api from "src/services/Api";
import {formatShiftData, formatSingleShiftData, getCurrency} from "src/utils";
import {useRecoilValue} from "recoil";
import {userState} from "src/services/GlobalState";
import moment from 'moment';
import {Dialog, CircularProgress, DialogTitle, DialogContent, DialogContentText, Button} from "@mui/material";
import {NotificationH1Style} from "src/utils/styles";
import toast from 'react-hot-toast';
function EditShiftModal({uuid, onClose, onFinishUpdatingShift}) {
  const user = useRecoilValue(userState);
  const api = Api.create();
  const [value, onChange] = useState(null);
  const [rawData, setRawData] = useState(null);
  const currency = getCurrency(user.country);
  useEffect(() => {
    onChange(null);
    setRawData(null);
    if (uuid) {
      api.getBaristaShift(uuid).then(({ ok, data }) => {
        setRawData(data);
        const shiftData = {
          date_list: [data.date],
          start_time: moment(data.start_time, ['HH:mm']),
          end_time: moment(data.end_time, ['HH:mm']),
          date_csv: [data.date].join(','),
          site: data.site.uuid,
          num_barista: data.baristas_required,
          barista_uuid: [],
          boost: parseFloat(data.boost || '0'),
        };
        api.estimateCost(formatShiftData(shiftData)).then(({ok, data: estimate}) => {
          onChange({
            ...shiftData,
            estimate,
          });
        })
      });
    }
  }, [uuid]);
  const onConfirm = useCallback(
    () => {
      api.postBaristaShift({
        uuid: uuid,
        ...formatSingleShiftData(value)
      }).then(({ok, data}) => {
        if (ok) {
          toast.success(`Shift updated.`);
          onFinishUpdatingShift();
          onClose();
        } else {
          toast.error(data.error_message || 'Error, unable to update shift.');
        }
      });
    },
    [value, uuid],
  );
  const makePublic = useCallback(
    () => {
      api.makeShiftPublic({
        uuid: uuid,
        ...formatSingleShiftData(value)
      }).then(({ok}) => {
        if (ok) {
          toast.success(`Shift made public.`);
          onFinishUpdatingShift();
          onClose();
        }
      });
    },
    [value, uuid]
  );
  if (value === null) {
    return (
      <Dialog open={!!uuid}>
        <div style={{padding: 20, textAlign: 'center'}}>
          <CircularProgress />
          <div style={{marginTop: 20}}>
            Loading ...
          </div>
        </div>
      </Dialog>
    )
  }
  const shiftOfferedTo = rawData?.barista_shift_offers
    .map(barista => barista.first_name + ' ' + barista.last_name)
    .join('\n');
  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle sx={NotificationH1Style}>
        {`EDIT SHIFT - ${moment(rawData.date).format('DD MMM YYYY')} - ${rawData.status}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            fontSize: '16px',
            whiteSpace: 'pre-line',
            marginBottom: '20px',
          }}
        >
          <BookShiftForm
            value={value}
            isEdit
            hideDelete
            isExpanded
            onSelect={() => ''}
            onDelete={() => ''}
            onChange={(newValue, clearEstimate=true) => onChange({
              ...value,
              ...newValue,
              barista_uuid: clearEstimate ? [] : newValue.barista_uuid,
              estimate: clearEstimate ? null : value.estimate,
            })}
            onChangeEstimate={(newValue) => onChange({...value, ...newValue})}
            currency={currency}
          />
          {shiftOfferedTo && <div style={{...NotificationH1Style, marginTop: 0, marginBottom: 10}}>SHIFT OFFERED TO</div>}
          <div style={{fontWeight: 500}}>
            {shiftOfferedTo}
          </div>
        </DialogContentText>
        {!rawData.is_public && (
          <Button
            fullWidth
            variant="contained"
            onClick={makePublic}
          >MAKE PUBLIC</Button>
        )}
      </DialogContent>
      <div style={{padding: '20px', display: 'flex'}}>
        <Button
          fullWidth
          variant="outlined"
          sx={{marginRight: '10px'}}
          onClick={onClose}
        >CANCEL</Button>
        <Button
          fullWidth
          variant="contained"
          onClick={onConfirm}
          disabled={!value.estimate}
        >UPDATE</Button>
      </div>
    </Dialog>
  );
}
export default EditShiftModal;
