import React from "react"
import Api from "../services/Api";
import {useRecoilValue} from "recoil";
import {userState} from "../services/GlobalState";
import {Backdrop, CircularProgress} from "@mui/material";
import toast from 'react-hot-toast';


const ApiReducer = () => {
  return (state, action) => {
    switch (action.type) {
      case 'setList': {
        return {list: action.data}
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`)
      }
    }
  }
};

const SiteReducer = ApiReducer();
const BaristaReducer = ApiReducer();
const NotificationReducer = ApiReducer();

export const AppContext = React.createContext({
  siteList: [],
  baristaList: [],
  notificationList: [],
  fetchNotificationList: () => '',
  fetchSiteList: () => '',
  fetchBaristaList: () => '',
  setShowLoadingScreen: () => '',
  saveSite: () => '',
  deleteSite: () => '',
  addBaristaToFavourites: () => '',
})

export const AppProvider = ({ children }) => {
  const [isLoadingNotification, setIsLoadingNotification] = React.useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = React.useState(false);
  const [saveSiteErrors, setSaveSiteErrors] = React.useState({});
  const [siteState, dispatchSite] = React.useReducer(SiteReducer, {list: []})
  const [baristaState, dispatchBarista] = React.useReducer(BaristaReducer, {list: []})
  const [notificationState, dispatchNotification] = React.useReducer(NotificationReducer, {list: []})
  const user = useRecoilValue(userState);

  const api = Api.create();
  const fetchSiteList = React.useCallback(() => {
    api.getSiteList().then(({ ok, data }) => {
      if (ok) {
        dispatchSite({type: 'setList', data});
      }
    });
  });

  const fetchNotificationList = React.useCallback(async () => {
    if (isLoadingNotification) {
      return false;
    }
    setIsLoadingNotification(true);
    const shiftRes = await api.getNotificationList();
    if (shiftRes.ok) {
      dispatchNotification({type: 'setList', data: shiftRes.data});
    }
    setIsLoadingNotification(false);
  });

  const fetchBaristaList = React.useCallback((dateCsv='') => {
    api.getBaristaList({date_csv: dateCsv}).then(({ ok, data }) => {
      if (ok) {
        dispatchBarista({type: 'setList', data});
      }
    });
  });

  const removeBaristaFromFavourites = React.useCallback((baristaUuid) => {
    api.removeBaristaFromFavourites(baristaUuid).then(({ ok, data }) => {
      fetchBaristaList();
      toast.success(`Barista removed from roster.`);
    });
  }, [fetchBaristaList]);

  const addBaristaToFavourites = React.useCallback((baristaUuid) => {
    api.addBaristaToFavourites(baristaUuid).then(({ ok, data }) => {
      fetchBaristaList();
      toast.success(`Barista added to roster.`);
    });
  }, [fetchBaristaList]);

  const saveSite = React.useCallback((site) => {
    setShowLoadingScreen(true);
    api.postSite(site).then(({ ok, data }) => {
      if (ok) {
        fetchSiteList();
        toast.success(`Site ${site.name} saved.`);
      } else {
        setSaveSiteErrors(data);
      }
      setShowLoadingScreen(false);
    });
  }, [fetchSiteList]);

  const deleteSite = React.useCallback((site) => {
    setShowLoadingScreen(true);
    api.deleteSite(site).then(({ ok, data }) => {
      fetchSiteList();
      setShowLoadingScreen(false);
      toast.success(`Site deleted.`);
    });
  }, [fetchSiteList]);

  React.useEffect(() => {
    if (user) {
      fetchSiteList();
      fetchBaristaList();
      fetchNotificationList();

      const intervalId = setInterval(() => {
        fetchNotificationList();
      }, 1000 * 60 * 2);

      return () => clearInterval(intervalId);
    }
  }, [user]);

  return (
    <AppContext.Provider value={{
      siteList: siteState.list,
      baristaList: baristaState.list,
      notificationList: notificationState.list,
      fetchSiteList,
      saveSite,
      fetchBaristaList,
      fetchNotificationList,
      removeBaristaFromFavourites,
      addBaristaToFavourites,
      setShowLoadingScreen,
      deleteSite,
      saveSiteErrors,
    }}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showLoadingScreen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      { children }
    </AppContext.Provider>
  )
}

export default function useAppData() {
  return React.useContext(AppContext)
}

