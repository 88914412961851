import {useEffect, useState} from "react";
import {Snackbar, Alert} from "@mui/material";
import Card from '@mui/material/Card';
import Api from 'src/services/Api';
import NotificationListItem from './components/NotificationListItem';
import NotificationView from './components/NotificationView';
import ShiftAcceptedNotification from './components/ShiftAcceptedNotification';
import SplitLayout from "src/layouts/components/SplitLayout";
import RateBaristaScreen from './components/RateBaristaScreen';
import useErrorHander from "src/contexts/ErrorHandler";
import useAppData from "src/contexts/AppData";

function NotificationPage() {
  const api = Api.create()
  const [showShiftUpdatedSnackbar, setShowShiftUpdatedSnackbar] = useState(false);
  const [showRatingUpdatedSnackbar, setShowRatingUpdatedSnackbar] = useState(false);
  const [showRejectBaristaSnackbar, setShowRejectBaristaSnackbar] = useState(false);
  const [agreementHtml, setAgreementHtml] = useState('<div>Loading...</div>');
  const [selectedNotification, setSelectedNotification] = useState(null);
  const {setShowLoadingScreen, notificationList: list, fetchNotificationList} = useAppData();
  const {setError} = useErrorHander();

  useEffect(() => {
    fetchNotificationList();
  }, []);


  const markAsRead = async (notification) => {
    await api.postNotification({...notification, is_read: true});
    await fetchNotificationList();
    setSelectedNotification({...notification, is_read: true});
  }

  const postRating = async (ratingData, selectedNotification) => {
    await api.rate(ratingData);
    await markAsRead(selectedNotification);
    setShowRatingUpdatedSnackbar(true);
  }

  const handleRate = async (ratingData, updateShiftTimeData, selectedNotification) => {
    setShowLoadingScreen(true);
    if (updateShiftTimeData) {
      const res = await api.postBaristaShift(updateShiftTimeData);
      if (res.status === 200) {
        setShowShiftUpdatedSnackbar(true);
        await postRating(ratingData, selectedNotification);
      } else {
        setError(res.data);
      }
    } else {
      await postRating(ratingData, selectedNotification);
    }
    setShowLoadingScreen(false);
  }

  const rejectBarista = async (notification, isBlockBooking=true) => {
    const notificationData = notification.data;
    setShowLoadingScreen(true);
    let res;
    if (isBlockBooking) {
      res = await api.customerRejectBlockBooking({
        uuid: notificationData.shift_uuid,
        barista_uuid: notificationData.barista_uuid
      })
    } else {
      res = await api.rejectBaristaShift({
        uuid: notificationData.shift_uuid,
        barista_uuid: notificationData.barista_uuid
      })
    }
    if (res.status === 200) {
      setShowRejectBaristaSnackbar(true);
      await markAsRead(notification);
    } else {
      setError(res.data);
    }
    setShowLoadingScreen(false);
  }

  const handleSelectNotification = async (notification) => {
    if (notification.is_read === false && ['NOTIFICATION', 'AGREEMENT'].indexOf(notification.type) > -1) {
      await markAsRead(notification);
    }

    if (notification.type === 'AGREEMENT') {
      const agreementData = await api.getTermsOfService();
      setAgreementHtml(agreementData.data);
    }

    setSelectedNotification(notification);
  }

  const markAllAsRead = async () => {
    await api.getNotificationMarkAllAsRead();
    await fetchNotificationList();
  }

  return (
    <div>
      <Snackbar open={showRejectBaristaSnackbar} autoHideDuration={6000} onClose={() => setShowRejectBaristaSnackbar(false)}>
        <Alert onClose={() => setShowRejectBaristaSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          You have requested a different barista
        </Alert>
      </Snackbar>
      <Snackbar open={showShiftUpdatedSnackbar} autoHideDuration={6000} onClose={() => setShowShiftUpdatedSnackbar(false)}>
        <Alert onClose={() => setShowShiftUpdatedSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Shift times updated
        </Alert>
      </Snackbar>
      <Snackbar open={showRatingUpdatedSnackbar} autoHideDuration={6000} onClose={() => setShowRatingUpdatedSnackbar(false)}>
        <Alert onClose={() => setShowRatingUpdatedSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Rating sent
        </Alert>
      </Snackbar>

      <SplitLayout
        leftPanel={
        <div>
          {list.map(notification => (
            <NotificationListItem
              key={notification.uuid}
              {...notification}
              onClick={() => handleSelectNotification(notification)}
              isSelected={selectedNotification && selectedNotification.uuid === notification.uuid}
            />
          ))}
        </div>
        }
        rightPanel={selectedNotification && (
          <Card elevation={3} sx={{padding: '20px'}}>
            {selectedNotification.type === 'NOTIFICATION' &&
              <NotificationView
                subheading={selectedNotification.data.subtitle}
                notificationText={selectedNotification.data.text}
                heading={selectedNotification.title}
              />
            }

            {selectedNotification.type === 'SHIFT_ACCEPTED' &&
              <ShiftAcceptedNotification
                {...selectedNotification.data}
                isRead={selectedNotification.is_read}
                onAccept={() => markAsRead(selectedNotification)}
                onReject={() => rejectBarista(selectedNotification, false)}
              />
            }

            {selectedNotification.type === 'BLOCK_BOOKING_ACCEPTED' &&
              <ShiftAcceptedNotification
                {...selectedNotification.data}
                isRead={selectedNotification.is_read}
                isBlockBooking
                onAccept={() => markAsRead(selectedNotification)}
                onReject={() => rejectBarista(selectedNotification, true)}
              />
            }

            {selectedNotification.type === 'AGREEMENT' &&
              <iframe
                style={{width: '100%', height: '750px', border: 0}}
                srcDoc={agreementHtml}
              />
            }

            {selectedNotification.type === 'RATE' &&
              <RateBaristaScreen
                {...selectedNotification.data}
                isRead={selectedNotification.is_read}
                heading={selectedNotification.title}
                onSubmit={async (ratingData, updateShiftTimeData) => {
                  await handleRate(ratingData, updateShiftTimeData, selectedNotification);
                }}
              />
            }
          </Card>
        )}
      />
    </div>
  )
}

export default NotificationPage;
