import React from "react";
import moment from 'moment';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {FormHelperText} from "@mui/material";

const TimePicker = ({value, onChange, error}) => {
  const hour = value.format('hh');
  const minute = value.format('mm');
  const amPm = value.format('a');
  const inputStyle = {
    color: 'rgb(52, 69, 52)',
    fontSize: '18px',
    fontWeight: 700,
    '.MuiOutlinedInput-notchedOutline': {
      border: 'none',
      top: 0,
    },
    '.MuiInputBase-input': {
      padding: '5px !important',
    },
    '.MuiSelect-icon': {
      display: 'none',
    }
  }

  return (
    <div>
      <div style={{
        ...inputStyle,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '8px',
        paddingLeft: '10px',
        paddingRight: '10px'
      }}>
        <Select
          sx={inputStyle}
          value={hour}
          onChange={(evt) => onChange(
            moment(`${evt.target.value}:${minute}${amPm}`, ['hh:mma'])
          )}
        >
          {[...Array(13).keys()].map((val, idx) => (
            <MenuItem key={idx} value={val.toString().padStart(2, '0')}>{val.toString().padStart(2, '0')}</MenuItem>
          ))}
        </Select>
        <span>:</span>
        <Select
          sx={inputStyle}
          value={minute}
          onChange={(evt) => onChange(
            moment(`${hour}:${evt.target.value}${amPm}`, ['hh:mma'])
)}
        >
          {['00', '15', '30', '45'].map((val, idx) => (
            <MenuItem key={idx} value={val}>{val}</MenuItem>
          ))}
        </Select>
        <Select
          sx={{
            ...inputStyle,
            '.MuiInputBase-input': {
              padding: '5px !important',
              paddingLeft: '0 !important',
            },
          }}
          value={amPm}
          onChange={(evt) => onChange(
            moment(`${hour}:${minute}${evt.target.value}`, ['hh:mma'])
          )}
        >
          {['am', 'pm'].map((val, idx) => (
            <MenuItem key={idx} value={val}>{val}</MenuItem>
          ))}
        </Select>
      </div>
      {error && <FormHelperText>{error}</FormHelperText>}
    </div>
  )
}

export default TimePicker;
