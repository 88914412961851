// ** React Imports
import {useState, Fragment} from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** Icons Imports
import CogOutline from 'mdi-material-ui/CogOutline'
import LogoutVariant from 'mdi-material-ui/LogoutVariant'
import AccountOutline from 'mdi-material-ui/AccountOutline'
import {useRecoilValue, useSetRecoilState} from "recoil";
import {userSelector, userState} from "src/services/GlobalState";
import Api from "../../../../services/Api";

// ** Styled Components
const BadgeContentSpan = styled('span')(({theme}) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))

const ProfileDropdown = props => {
  const {settings} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useRecoilValue(userSelector);
  const setUser = useSetRecoilState(userState);
  const api = Api.create()

  const {direction} = settings

  const logout = () => {
    setUser(null);
    api.logout().then(() => window.location.reload());
  }

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = url => {
    setAnchorEl(null)
  }

  const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
      fontSize: '1.375rem',
      color: 'text.secondary'
    }
  }

  const handleLogout = () => {
    logout()
    handleDropdownClose()
  }

  return (
    <Fragment>
      <Badge
        overlap='circular'
        onClick={handleDropdownOpen}
        sx={{ml: 2, cursor: 'pointer'}}
        badgeContent={<BadgeContentSpan/>}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Avatar
          alt={user.displayName}
          onClick={handleDropdownOpen}
          sx={{width: 40, height: 40}}
        />
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{'& .MuiMenu-paper': {width: 230, mt: 4}}}
        anchorOrigin={{vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left'}}
        transformOrigin={{vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left'}}
      >
        <Box sx={{pt: 2, pb: 3, px: 4}}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Badge
              overlap='circular'
              badgeContent={<BadgeContentSpan/>}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
            >
              <Avatar alt={user.displayName} sx={{width: '2.5rem', height: '2.5rem'}}/>
            </Badge>
            <Box sx={{display: 'flex', ml: 3, alignItems: 'flex-start', flexDirection: 'column'}}>
              <Typography sx={{fontWeight: 600}}>{user.displayName}</Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{mt: 0, mb: 1}}/>
        {/*<MenuItem sx={{p: 0}} onClick={() => handleDropdownClose('/apps/user/view/12')}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <AccountOutline sx={{mr: 2}}/>*/}
        {/*    Profile*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem sx={{p: 0}} onClick={() => handleDropdownClose('/pages/account-settings')}>*/}
        {/*  <Box sx={styles}>*/}
        {/*    <CogOutline sx={{mr: 2}}/>*/}
        {/*    Settings*/}
        {/*  </Box>*/}
        {/*</MenuItem>*/}
        <MenuItem sx={{p: 0}} onClick={handleLogout}>
          <Box sx={styles}>
            <LogoutVariant sx={{mr: 2, fontSize: '1.375rem', color: 'text.secondary'}}/>
            Logout
          </Box>
        </MenuItem>
      </Menu>
    </Fragment>
  )
}

export default ProfileDropdown
