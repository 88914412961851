
export const getCurrency = (country) => {
  if (country === 'United Kingdom') {
    return '£';
  }
  if (country === 'Republic of Ireland') {
    return '€';
  }
  return '$';
};

export const addOrRemove = (arr, item) => arr.includes(item) ? arr.filter(i => i !== item) : [ ...arr, item ];

export const round = (num) => Math.round(num * 100) / 100;

export const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const baristaToString = (barista) => {
  return `${barista.first_name} ${barista.last_name}${barista.pronouns && ` (${barista.pronouns})`}`;
}

export const capitalizeFirstLetter = (string) => {
  if (typeof string === 'string' || string instanceof String) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  return string;
}

export const formatShiftData = (shiftGroup) => {
  return {
    date_list: shiftGroup.date_list.map((shiftDate, index) => {
      return {
        start_time: shiftGroup.start_time.format('HH:mm'),
        end_time: shiftGroup.end_time.format('HH:mm'),
        boost: shiftGroup.boost,
        date: shiftDate,
      }
    }),
    start_time: shiftGroup.start_time.format('HH:mm'),
    end_time: shiftGroup.end_time.format('HH:mm'),
    date_csv: shiftGroup.date_list.join(','),
    site: shiftGroup.site,
    num_barista: shiftGroup.num_barista,
    barista_uuid: shiftGroup.barista_uuid.join(','),
  }
}

export const formatSingleShiftData = (shiftGroup) => {
  return {
    start_time: shiftGroup.start_time.format('HH:mm'),
    end_time: shiftGroup.end_time.format('HH:mm'),
    site: shiftGroup.site,
    boost: shiftGroup.boost,
  }
}

export const NOTIFY_TO_STR_MAPPING = {
  notify_mon: 'Mon',
  notify_tue: 'Tue',
  notify_wed: 'Wed',
  notify_thu: 'Thu',
  notify_fri: 'Fri',
  notify_sat: 'Sat',
  notify_sun: 'Sun',
};

export const DAY_MAPPING = Object.keys(NOTIFY_TO_STR_MAPPING);
