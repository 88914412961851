export const NotificationH1Style = {
  fontSize: '18px',
  fontWeight: '700',
  textTransform: 'uppercase',
  color: '#344534',
  marginBottom: '10px',
}

export const NotificationH2Style = {
  fontWeight: 700,
  fontSize: '14px',
  textTransform: 'uppercase',
  color: '#344534',
  marginBottom: '5px',
}

export const NotificationH3Style = {
  fontWeight: 500,
  fontSize: '12px',
  textTransform: 'uppercase',
  color: '#777777',
  marginBottom: '8px',
}

export const TimePickerTextStyle = {
  WebkitTextFillColor: '#344534',
  color: '#344534',
  fontSize: '18px',
  fontWeight: '700',
  padding: '6px 0 6px 15px',
  width: '100px',
}

export const PillStyle = {
  backgroundColor: '#FF4B95',
  display: 'inline-flex',
  height: '30px',
  width: '136px',
  borderRadius: '40px',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
}