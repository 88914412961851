import React, { Component } from 'react'
import Moment from 'moment'
import ListItem from "src/layouts/components/ListItem";


export default class NotificationListItem extends Component {
  render () {
    const {title, is_read, created, data, type, isSelected, onClick} = this.props

    return (
      <ListItem
        title={title}
        subtitle={
          <>
            {data.subtitle && `${data.subtitle} on `}
            {type === 'SHIFT_ACCEPTED' && `${data.barista_name} ${data.date} ${data.start_time} - ${data.end_time} on `}
            {type === 'BLOCK_BOOKING_ACCEPTED' && `${data.barista_name} accepted ${data.shifts.length} shifts on `}
            {type === 'RATE' && `${data.name} on `}
            {(type === 'SHIFT_CHANGED' || type === 'CONFIRM_REPEAT_BOOKING') &&
              `${data.site && data.site.name} ${data.date} ${data.start_time} - ${data.end_time} on `
            }
            {Moment(created).format('DD/MM/YYYY')}
          </>
        }
        isSelected={isSelected}
        onSelect={onClick}
        style={{
          opacity: is_read ? 0.6 : 1,
        }}
      />
    )
  }
}
