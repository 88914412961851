import React, {useEffect} from 'react';
import {Controller, useForm} from "react-hook-form";
import {
  Button,
  FormControl,
  OutlinedInput,
  InputLabel,
  Card,
  TextField,
  CardContent,
  FormHelperText,
} from "@mui/material";
import {NotificationH1Style, NotificationH2Style} from "src/utils/styles";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const SiteForm = ({initialValue, onSubmit, onDelete, apiErrors}) => {
  const {reset, handleSubmit, control, register, setError, formState: {errors}} = useForm({
    defaultValues: initialValue
  });

  useEffect(() => {
    for (const field in apiErrors) {
      setError(field, {type: 'manual', message: apiErrors[field]});
    }
  }, [apiErrors]);

  useEffect(() => {
    reset(initialValue);
  }, [initialValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('uuid')} />
      <Card sx={{marginBottom: '20px', width: '100%', position: 'relative'}}>
        <CardContent sx={{padding: '15px 20px 20px 20px !important'}}>
          <div style={NotificationH1Style}>
            {initialValue.uuid ? 'Edit Site' : 'Add New Site'}
          </div>
          <div style={{marginTop: 20}}>
            <FormControl sx={{width: '100%', marginBottom: '20px'}} error={errors?.name}>
              <div style={NotificationH2Style}>Site name</div>
              <Controller
                name={"name"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.name && <FormHelperText>{errors?.name.message}</FormHelperText>}
            </FormControl>
          </div>
          <div>
            <FormControl sx={{width: '100%', marginBottom: '20px'}} error={errors?.address}>
              <div style={NotificationH2Style}>Address</div>
              <Controller
                name={"address"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.address && <FormHelperText>{errors?.address.message}</FormHelperText>}
            </FormControl>
          </div>
          <div>
            <FormControl sx={{width: '100%', marginBottom: '20px'}} error={errors?.postcode}>
              <div style={NotificationH2Style}>Postcode</div>
              <Controller
                name={"postcode"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.postcode && <FormHelperText>{errors?.postcode.message}</FormHelperText>}
            </FormControl>
          </div>
          <div>
            <FormControl sx={{width: '100%', marginBottom: '20px'}} error={errors?.espresso_machine}>
              <div style={NotificationH2Style}>Espresso machine</div>
              <Controller
                name={"espresso_machine"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.espresso_machine && <FormHelperText>{errors?.espresso_machine.message}</FormHelperText>}
            </FormControl>
          </div>
          <div>
            <FormControl sx={{width: '100%', marginBottom: '20px'}} error={errors?.grinder}>
              <div style={NotificationH2Style}>Grinder</div>
              <Controller
                name={"grinder"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.grinder && <FormHelperText>{errors?.grinder.message}</FormHelperText>}
            </FormControl>
          </div>
          <div>
            <FormControl sx={{width: '100%', marginBottom: '20px'}} error={errors?.coffee_supplier}>
              <div style={NotificationH2Style}>Coffee Supplier</div>
              <Controller
                name={"coffee_supplier"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <OutlinedInput
                    type="text"
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors?.coffee_supplier && <FormHelperText>{errors?.coffee_supplier.message}</FormHelperText>}
            </FormControl>
          </div>
          <div>
            <FormControl sx={{width: '100%', marginBottom: '20px'}} error={errors?.notes}>
              <div style={NotificationH2Style}>Site Notes</div>
              <Controller
                name={"notes"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextareaAutosize
                    minRows={6}
                    placeholder=""
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {errors?.notes && <FormHelperText>{errors?.notes.message}</FormHelperText>}
            </FormControl>
          </div>

          <Button type="submit" variant="contained" fullWidth>
            {initialValue.uuid ? 'UPDATE SITE' : 'CREATE SITE'}
          </Button>
          {initialValue.uuid && <Button
            variant="outlined"
            fullWidth sx={{marginTop: '10px'}}
            onClick={onDelete}
          >DELETE SITE</Button>}
        </CardContent>
      </Card>
    </form>
  )
}

export default SiteForm;
