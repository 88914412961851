import React from 'react';
import './index.css';
import App from './App';
import ReactDOM from 'react-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import UserLayout from 'src/layouts/components/UserLayout';
import {RecoilRoot} from 'recoil';
import userTheme from 'src/configs/userTheme';
import {ThemeProvider} from "@mui/material/styles";
import {BrowserRouter} from "react-router-dom";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AppProvider} from "./contexts/AppData";
import {ErrorHandlerProvider} from "./contexts/ErrorHandler";
import { Toaster } from 'react-hot-toast';


ReactDOM.render(
  <RecoilRoot>
    <AppProvider>
      <BrowserRouter>
        <ThemeProvider theme={userTheme}>
          <UserLayout>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ErrorHandlerProvider>
                <App/>
                <Toaster
                  position="bottom-center"
                  toastOptions={{
                    duration: 10000,
                  }}
                />
              </ErrorHandlerProvider>
            </LocalizationProvider>
          </UserLayout>
        </ThemeProvider>
      </BrowserRouter>
    </AppProvider>
  </RecoilRoot>,
  document.getElementById('root')
);
