import {useState} from "react";
import useAppData from "src/contexts/AppData";
import SplitLayout from "src/layouts/components/SplitLayout";
import SiteForm from './components/SiteForm';
import ListItem from 'src/layouts/components/ListItem';
import ConfirmModal from "../../layouts/components/ConfirmModal";

function SitePage() {
  const INITIAL_SITE = {
    uuid: null,
    name: '',
    address: '',
    postcode: '',
    espresso_machine: '',
    grinder: '',
    coffee_supplier: '',
    notes: '',
  };
  const {siteList, saveSite, deleteSite, saveSiteErrors} = useAppData();
  const [selectedSite, setSelectedSite] = useState(INITIAL_SITE);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  return (
    <SplitLayout
      leftPanel={
        <>
          <ConfirmModal
            title="DELETE SITE?"
            confirmButtonText="DELETE IT!"
            open={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            onConfirm={() => {
              deleteSite(selectedSite);
              setShowConfirmModal(false);
              setSelectedSite(INITIAL_SITE);
            }}
          >
            This action cannot be undone - but you can create this site again at any time.
          </ConfirmModal>
          <div>
            {siteList.map(site => (
              <ListItem
                key={site.uuid}
                title={site.name}
                subtitle={`${site.address}, ${site.postcode}`}
                isSelected={selectedSite.uuid === site.uuid}
                onSelect={() => setSelectedSite(site)}
              />
            ))}
          </div>
          <div style={{textAlign: 'center'}}>
            <ListItem
              title="ADD NEW SITE"
              isSelected={selectedSite.uuid === null}
              onSelect={() => setSelectedSite(INITIAL_SITE)}
            />
          </div>
        </>
      }
      rightPanel={
        <>
          {selectedSite && (
            <SiteForm
              initialValue={selectedSite}
              onSubmit={(site) => {
                saveSite(site);
                setSelectedSite(INITIAL_SITE);
              }}
              apiErrors={saveSiteErrors}
              onDelete={() => setShowConfirmModal(true)}
            />
          )}
        </>
      }
    />
  )
}

export default SitePage;
