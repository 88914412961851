import { useMemo } from 'react';
import { Link, useLocation } from "react-router-dom";

// ** MUI Imports
import Chip from '@mui/material/Chip'
import ListItem from '@mui/material/ListItem'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import {styled, useTheme} from '@mui/material/styles'
import ListItemButton from '@mui/material/ListItemButton'

// ** Configs Import
import themeConfig from 'src/configs/themeConfig'

// ** Custom Components Imports
import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'

// ** Styled Components
const MenuNavLink = styled(ListItemButton)(({theme}) => ({
  width: '100%',
  borderRadius: '0 10px 10px 0',
  color: '#777777',
  transition: 'padding-left .25s ease-in-out',
  '&.active': {
    backgroundColor: 'rgba(255, 75, 149, 0.2)',
    '& .MuiTypography-root, & .MuiListItemIcon-root': {
      color: '#FF4B95',
    }
  }
}))

const MenuItemTextMetaWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && {overflow: 'hidden'})
})

const VerticalNavLink = ({
  item,
  parent,
  navHover,
  settings,
  navVisible,
  isSubToSub,
  toggleNavVisibility,
}) => {
  const {navCollapsed} = settings;
  const location = useLocation();

  const IconTag = parent && !item.icon ? themeConfig.navSubItemIcon : item.icon

  const isNavLinkActive = useMemo(() => {
    return location.pathname === item.path;
  }, [item.path, location.pathname]);

  return (
    <ListItem
      disablePadding
      className='nav-link'
      disabled={item.disabled || false}
      sx={{
        paddingLeft: '0 !important',
      }}
    >
      <Link to={item.path === undefined ? '/' : `${item.path}`} style={{textDecoration: 'none', width: '100%'}}>
        <MenuNavLink
          component={'div'}
          className={isNavLinkActive ? 'active' : ''}
          {...(item.openInNewTab ? {target: '_blank'} : null)}
          onClick={e => {
            if (item.path === undefined) {
              e.preventDefault()
              e.stopPropagation()
            }
            if (navVisible) {
              toggleNavVisibility()
            }
          }}
          sx={{
            height: '40px',
            ...(item.disabled ? {pointerEvents: 'none'} : {cursor: 'pointer'}),
            pl: '20px !important',
          }}
        >
          {isSubToSub ? null : (
            <ListItemIcon
              sx={{
                transition: 'margin .25s ease-in-out',
                ...(navCollapsed && !navHover ? {mr: 5} : {mr: '14px'}),
                ...(parent ? {ml: 1.25, mr: 3.75} : {}),
                minWidth: 'auto',
              }}
            >
              <UserIcon
                icon={IconTag}
                componentType='vertical-menu'
                iconProps={{
                  sx: {
                    fontSize: '20px',
                  }
                }}
              />
            </ListItemIcon>
          )}

          <MenuItemTextMetaWrapper
            sx={{
              ...(isSubToSub ? {ml: 9} : {}),
              ...{opacity: 1},
            }}
          >
            <Typography
              {...((themeConfig.menuTextTruncate || (!themeConfig.menuTextTruncate && navCollapsed && !navHover)) && {
                noWrap: true
              })}
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '15px',
              }}
            >
              <Translations text={item.title}/>
            </Typography>
          </MenuItemTextMetaWrapper>
        </MenuNavLink>
      </Link>
    </ListItem>
  )
}

export default VerticalNavLink
