import React, { Component } from 'react'
import {Button} from '@mui/material';
import {NotificationH1Style} from 'src/utils/styles';
import Star from 'mdi-material-ui/Star'
import Rating from "@mui/material/Rating";


class NotificationView extends Component {
  render () {
    const {
      shift_uuid,
      barista_uuid,
      barista_name,
      barista_rating,
      start_time,
      end_time,
      date,
      isBlockBooking = false,
      shifts = [],
      onAccept,
      onReject,
      isRead,
    } = this.props

    return (
      <div>
        <div style={NotificationH1Style}>SHIFT ACCEPTED</div>
        <div style={{textAlign: 'center', fontWeight: 600}}>
          <div>Your shift has been accepted by:</div><br/>
          <div>{barista_name}</div>
          <div>
            <Rating
              size="large"
              value={barista_rating}
              precision={0.1}
              sx={{color: '#FF4B95'}}
            />
          </div><br/>
          <div>For the shifts:</div>
          {isBlockBooking ?
            (
              shifts.map((shift, idx) => {
                return <div key={idx}>
                  {shift.start_time} - {shift.end_time} on {shift.date}
                </div>
              })
            ) : (
              <div>
                {start_time} - {end_time} on {date}
              </div>
            )
          }
        </div>
        {isRead || <div style={{marginTop: 20}}>
          <div style={{marginBottom: 10}}>
            <Button onClick={onAccept} fullWidth variant='outlined'>MARK AS READ</Button>
          </div>
          <div>
            <Button onClick={onReject} fullWidth variant='contained'>REQUEST DIFFERENT BARISTA</Button>
          </div>
        </div>}
      </div>
    )
  }
}

export default NotificationView
