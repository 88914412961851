import apisauce from 'apisauce';
import Cookies from 'js-cookie';

const create = (baseURL = process.env.REACT_APP_BASE_API_DOMAIN) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      'X-CSRFToken': Cookies.get('csrftoken'),
    },
    timeout: 30000,
    withCredentials: true,
  })

  const postCustomer = (data) => {
    if (data.uuid) {
      return api.put('/api/barista_booking/v1/customer/' + data.uuid + '/', data)
    }
    api.setHeader('Authorization', null)
    return api.post('/api/barista_booking/v1/customer/', data)
  }
  const login = (data) => {
    api.setHeader('Authorization', null)
    return api.post('/api/auth/v1/obtain-auth-token/', data)
  }

  const loginV2 = (data) => {
    return api.post('/api/auth/v1/login/', data)
  }
  const logout = () => {
    api.setHeader('Authorization', null)
    return api.get('/api/auth/v1/desktop/logout/')
  }
  const whoAmI = () => api.get('/api/auth/v1/whoami/')

  const getBaristaShift = (uuid) => api.get(`/api/barista_booking/v1/shift/${uuid}/`)
  const getBlockBooking = (uuid) => api.get(`/api/barista_booking/v2/block_booking/${uuid}/`)
  const getBaristaShiftList = (data) => api.get('/api/barista_booking/v1/shift/', data)

  const getBlockBookingList = (data) => api.get('/api/barista_booking/v2/block_booking/', data)

  const getNotificationList = () => api.get('/api/barista_booking/v1/notification/?all')
  const getNotificationMarkAllAsRead = () => api.get('/api/barista_booking/v1/notification/mark_all_as_read/')

  const getYourShiftList = (data) => api.get('/api/barista_booking/v1/your_shift/', data)
  const getYourBlockBookingList = (data) => api.get('/api/barista_booking/v2/your_block_booking/', data)
  const registerAPNSDevice = (data) => api.post('/api/barista_booking/v1/device/apns/', data)
  const registerOneSignalDevice = (data) => api.post('/api/barista_booking/v1/register_one_singal_device/', data)
  const registerFCMDevice = (data) => api.post('/api/barista_booking/v1/device/gcm/', data)

  const getCancellationPenaltyMessage = (shiftUuid) => api.get(
    '/api/barista_booking/v1/shift/' + shiftUuid + '/get_cancellation_penalty_message/')

  const getSiteList = () => api.get('/api/barista_booking/v1/site/')
  const getTermsOfService = () => api.get('/api/barista_booking/v1/terms_of_service/')
  const postBarista = (data) => {
    if (data.uuid) {
      return api.put('/api/barista_booking/v1/barista/' + data.uuid + '/', data)
    }
    api.setHeader('Authorization', null)
    return api.post('/api/barista_booking/v1/barista/', data)
  }
  const postNotification = data => {
    if (data.uuid) {
      return api.put('/api/barista_booking/v1/notification/' + data.uuid + '/', data)
    }
    return api.post('/api/barista_booking/v1/notification/', data)
  }
  const confirmBaristaShift = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v1/shift/' + data.uuid + '/confirm_shift/', data)
    }
  }
  const confirmBlockBooking = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v2/block_booking/' + data.uuid + '/confirm/', data)
    }
  }
  const rejectBaristaShift = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v1/shift/' + data.uuid + '/reject_barista/', data)
    }
  }
  const makeShiftPublic = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v1/shift/' + data.uuid + '/make_public/', data)
    }
  }
  const makeBlockBookingPublic = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v2/block_booking/' + data.uuid + '/make_public/', data)
    }
  }
  const rejectBlockBooking = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v2/your_block_booking/' + data.uuid + '/reject_barista/', data)
    }
  }
  const customerRejectBlockBooking = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v2/block_booking/' + data.uuid + '/reject_barista/', data)
    }
  }
  const rate = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v1/shift/' + data.uuid + '/rate/', data)
    }
  }

  const deleteSite = (data) => {
    if (data.uuid) {
      return api.delete('/api/barista_booking/v1/site/' + data.uuid + '/')
    }
  }

  const cancelBaristaShift = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v1/your_shift/' + data.uuid + '/cancel_shift/', data)
    }
  }

  const cancelBlockBooking = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v2/your_block_booking/' + data.uuid + '/cancel/', data)
    }
  }

  const acceptBaristaShift = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v1/your_shift/' + data.uuid + '/accept_shift/', data)
    }
  }

  const acceptBlockBooking = (data) => {
    if (data.uuid) {
      return api.post('/api/barista_booking/v2/your_block_booking/' + data.uuid + '/accept/', data)
    }
  }

  const postSite = (data) => {
    if (data.uuid) {
      return api.put('/api/barista_booking/v1/site/' + data.uuid + '/', data)
    }
    return api.post('/api/barista_booking/v1/site/', data)
  }
  const postBaristaShift = (data) => {
    if (data.uuid) {
      return api.put('/api/barista_booking/v1/shift/' + data.uuid + '/', data)
    }
    return api.post('/api/barista_booking/v2/create_booking/', data)
  }
  const deleteBaristaShift = (data) => {
    if (data.uuid) {
      return api.delete('/api/barista_booking/v1/shift/' + data.uuid + '/')
    }
  }
  const getInvoiceList = () => api.get('/api/barista_booking/v1/invoice/')
  const estimateCost = (data) => api.post('/api/barista_booking/v2/estimate_cost/', data)
  const updateEmail = (data) => api.post('/api/auth/v1/update_email/', data)
  const updatePassword = (data) => api.post('/api/auth/v1/change_password/', data)
  const forgotPassword = (data) => api.post('/api/auth/v1/forgot_password/', data)
  const getBaristaList = (data) => api.get('/api/barista_booking/v2/barista/', data)
  const getDownloadFileUrl = (uuid) => baseURL + '/api/barista_booking/v1/download_pdf/?uuid=' + uuid
  const removeBaristaFromFavourites = (uuid) => api.post(`/api/barista_booking/v1/barista/${uuid}/remove_favourite/`)
  const addBaristaToFavourites = (uuid) => api.post(`/api/barista_booking/v1/barista/${uuid}/add_favourite/`)

  return {
    apiObject: api,
    getBaristaShiftList,
    postBaristaShift,
    deleteBaristaShift,
    estimateCost,
    getSiteList,
    postSite,
    getInvoiceList,
    getBaristaList,
    postCustomer,
    login,
    loginV2,
    logout,
    whoAmI,
    updateEmail,
    updatePassword,
    postBarista,
    getYourShiftList,
    confirmBaristaShift,
    confirmBlockBooking,
    cancelBaristaShift,
    registerAPNSDevice,
    registerOneSignalDevice,
    getNotificationList,
    postNotification,
    registerFCMDevice,
    rejectBaristaShift,
    acceptBaristaShift,
    acceptBlockBooking,
    rejectBlockBooking,
    customerRejectBlockBooking,
    rate,
    deleteSite,
    forgotPassword,
    getCancellationPenaltyMessage,
    getBlockBookingList,
    getYourBlockBookingList,
    cancelBlockBooking,
    makeShiftPublic,
    makeBlockBookingPublic,
    getBaristaShift,
    getBlockBooking,
    getNotificationMarkAllAsRead,
    getDownloadFileUrl,
    getTermsOfService,
    removeBaristaFromFavourites,
    addBaristaToFavourites,
  }
}

export default {
  create
}
