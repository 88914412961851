import moment from 'moment';
import React, { Component } from 'react';
import {Button, TextField} from '@mui/material';
import Rating from '@mui/material/Rating';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import {DesktopTimePicker} from "@mui/x-date-pickers/DesktopTimePicker";
import {NotificationH2Style, NotificationH1Style, NotificationH3Style, TimePickerTextStyle} from "src/utils/styles";
import TimePicker from "../../../../layouts/components/TimePicker";


const RateBaristaScreen = ({heading, shift_uuid, user_uuid, name, start_time, end_time, date, isRead, onSubmit}) => {
  const [rating, setRating] = React.useState(0);
  const [notes, setNotes] = React.useState('');
  const [hover, setHover] = React.useState(-1);
  const [startTime, setStartTime] = React.useState(moment(date + ' ' + start_time));
  const [endTime, setEndTime] = React.useState(moment(date + ' ' + end_time));
  const ratingText = ['', 'Terrible', 'Poor', 'Average', 'Good', 'Great']
  const shift_times_correct = startTime.format('HH:mm') === start_time && endTime.format('HH:mm') === end_time;

  if (isRead) {
    return (
      <div>Rating already sent.</div>
    )
  }

  return (
    <div>
      <div style={NotificationH1Style}>{heading}</div>
      <div style={{marginBottom: 10, marginTop: 20, display: 'inline-block'}}>
        <div style={NotificationH2Style}>How was {name}?</div>
        <Rating
          size="large"
          value={rating}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
          onChangeActive={(event, newHover) => {
            setHover(newHover);
          }}
          sx={{color: '#FF4B95', fontSize: 42, margin: '5px 0'}}
        />
        <div style={{height: 20, fontWeight: 600, textAlign: 'center'}}>{ratingText[hover !== -1 ? hover : rating]}</div>
      </div>
      <div style={NotificationH2Style}>Confirm times for shift on {date}:</div>
      <div style={{marginBottom: 20, marginTop: 10, display: 'flex'}}>
        <div style={{marginRight: 20}}>
          <div style={NotificationH3Style}>From</div>
          <TimePicker
            value={startTime}
            onChange={(newStartTime) => setStartTime(newStartTime)}
          />
        </div>
        <div>
          <div style={NotificationH3Style}>To</div>
          <TimePicker
            value={endTime}
            onChange={(newEndTime) => setEndTime(newEndTime)}
          />
        </div>
      </div>
      <div style={NotificationH2Style}>Comments/feedback</div>
      <div style={{marginBottom: 20}}>
        <TextareaAutosize
          minRows={3}
          placeholder="Comments will only be visible to Baristas on Tap staff"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>
      <div>
        <Button
          variant='contained'
          fullWidth
          disabled={!rating || rating == 0}
          onClick={() => {
            if (!shift_times_correct) {
              onSubmit({
                uuid: shift_uuid,
                user_uuid,
                rating,
                shift_times_correct,
                notes
              }, {
                'uuid': shift_uuid,
                'start_time': startTime.format('HH:mm'),
                'end_time': endTime.format('HH:mm'),
                'time_update': true
              });
            } else {
              onSubmit({
                uuid: shift_uuid,
                user_uuid,
                rating,
                shift_times_correct,
                notes
              }, null);
            }
          }}
        >SUBMIT RATING {!shift_times_correct && '& TIME VARIATION'}</Button>
      </div>
    </div>
  )
}

export default RateBaristaScreen
