import React from "react";
import {Card} from "@mui/material";
import {NotificationH1Style, NotificationH3Style} from "src/utils/styles";
import ChevronRightWhite from "src/utils/chevron-right-w.svg";
import ChevronRight from "src/utils/chevron-right.svg";

const ListItem = ({isSelected, onSelect, title, subtitle, style={}, icon}) => {
  return (
    <Card
      sx={{
        marginBottom: '10px',
        padding: '15px 15px 0 20px',
        backgroundColor: isSelected ? "#FF4B95" : "white",
        ...style,
      }}
      onClick={onSelect}
    >
      <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '15px'}}>
        <div>
          <div style={{
            ...NotificationH1Style,
            marginBottom: 0,
            color: isSelected ? "white" : "black",
          }}>{title}</div>
          {subtitle && <div style={{
            ...NotificationH3Style,
            color: isSelected ? "white" : "black",
            textTransform: 'none',
            marginBottom: 0,
            marginTop: 6,
          }}>{subtitle}</div>}
        </div>
        {icon || <img src={isSelected ? ChevronRightWhite : ChevronRight} style={{
          width: '14px',
        }} />}
      </div>
    </Card>
  )
}

export default ListItem;