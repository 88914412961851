import React from "react"
import {Dialog, DialogTitle, Button, DialogContentText, DialogActions, DialogContent} from "@mui/material";

export const ErrorHandlerContext = React.createContext({
  error: '',
  setError: () => '',
})

export const ErrorHandlerProvider = ({ children }) => {
  const [error, setError] = React.useState(null);

  return (
    <ErrorHandlerContext.Provider value={{
      setError,
    }}>
      { children }
      <Dialog
        open={error !== null}
        onClose={() => setError(null)}
      >
        <DialogTitle>
          {error?.error_heading}<br/>
          {error?.error_subheading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {error?.error_message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setError(null)}>Close</Button>
        </DialogActions>
      </Dialog>
    </ErrorHandlerContext.Provider>
  )
}

export default function useErrorHander() {
  return React.useContext(ErrorHandlerContext)
}
