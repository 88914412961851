const SplitLayout = ({ leftPanel, rightPanel }) => {
  return (
    <div style={{display: 'flex', maxWidth: 1200}}>
      <div style={{flex: 1, marginRight: 10, height: 'calc(100vh - 96px)', display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
        {leftPanel}
      </div>
      <div style={{flex: 1, marginLeft: 10, height: 'calc(100vh - 96px)', display: 'flex', flexDirection: 'column', overflow: 'auto'}}>
        {rightPanel}
      </div>
    </div>
  )
};

export default SplitLayout;