import moment from 'moment-timezone';
import _ from 'lodash';

import Api from 'src/services/Api';
import React, {useEffect, useState, useCallback, useRef} from "react";
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import {
  Grid,
  Paper,
  Popover,
  FormGroup,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
  Chip,
  Tooltip,
  IconButton
} from "@mui/material";
import {useRecoilState} from 'recoil';
import {userState, shiftListState} from "src/services/GlobalState";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {NotificationH1Style, NotificationH3Style} from "src/utils/styles";
import FilterIcon from 'mdi-material-ui/FilterOutline'
import MapMarkerRadiusIcon from 'mdi-material-ui/MapMarkerRadius'
import AccountMultipleOutlineIcon from 'mdi-material-ui/AccountMultipleOutline'
import InformationOutline from 'mdi-material-ui/InformationOutline'
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ConfirmDeleteShiftModal from "./components/ConfirmDeleteShiftModal";
import useAppData from "src/contexts/AppData";
import {capitalizeFirstLetter} from "src/utils";
import EditShiftModal from "../components/EditShiftModal";

const localizer = momentLocalizer(moment)

const STATUS = {
  'ON OFFER': 0.6,
  'ACCEPTED': 1,
  'PENDING ACCEPTANCE': 0.6,
  // 'DELETED': '#F44238',
}

const COLORS = ['#FFA600', '#FF6361', '#BC5090', '#58508D', '#003F5C', '#94CE66', '#296698', '#E8E192', '#B390FA',];

const EventSummary = ({date, startTime, endTime, baristaName, siteName, status}) => (
  <>
    <div style={{
      fontSize: '14px',
      color: '#999999'
    }}>{moment(date).format('DD/MM/YYYY')}</div>
    <div style={{
      fontSize: '24px',
      fontWeight: 700,
      marginBottom: '10px',
    }}>{startTime} - {endTime}</div>
    {status && <div
      style={{
        fontSize: '16px',
        color: '#344534',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <InformationOutline style={{color: '#FF4B95', marginRight: '5px'}} /> {capitalizeFirstLetter(status)}
    </div> }
    {baristaName && <div
      style={{
        fontSize: '16px',
        color: '#344534',
        display: 'flex',
        alignItems: 'center'
      }}
    ><AccountMultipleOutlineIcon style={{color: '#FF4B95', marginRight: '5px'}} /> {baristaName}</div>}
    <div style={{
      fontSize: '16px',
      color: '#344534',
      marginBottom: '15px',
      display: 'flex',
      alignItems: 'center'
    }}><MapMarkerRadiusIcon style={{color: '#FF4B95', marginRight: '5px'}} /> {siteName}</div>
  </>
)

function DashboardPage() {
  const INITIAL_CONFIRM_DELETE_MODAL_PROPS = {
    show: false,
    shiftUuid: '',
    text: '',
    event: null,
  }
  const api = Api.create()
  const {setShowLoadingScreen} = useAppData();
  const [editingShiftUuid, setEditingShiftUuid] = useState(null);
  const [user, setUser] = useRecoilState(userState);
  const [confirmDeleteModalProps, setConfirmDeleteModalProps] = useState(INITIAL_CONFIRM_DELETE_MODAL_PROPS);
  const [showTooltip, setShowTooltip] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [date, setDate] = useState(new Date())
  const [view, setView] = useState(Views.MONTH)
  const filterRef = useRef(null);
  const [shiftList, setShiftList] = useRecoilState(shiftListState);
  const [appliedFilters, setAppliedFilters] = useState({
    status: [],
    site: [],
    start_date: null,
    end_date: null,
    baristas: [],
  });
  const sitesList = _.uniqBy(shiftList.map(shift => shift.site), 'uuid');
  const baristasList = _.uniqBy(_.flatten(shiftList.map(shift => shift.baristas)), 'uuid');
  const onNavigate = useCallback((newDate) => setDate(newDate), [setDate])
  const onView = useCallback((newView) => setView(newView), [setView])

  const getSiteNameList = useCallback(
    (siteUuidList) => {
      return sitesList.filter(site => siteUuidList.indexOf(site.uuid) > -1).map((site) => site.name).join(', ');
    },
    [sitesList]
  );

  const handleEditShift = (shiftUuid) => {
    setShowTooltip(null);
    setEditingShiftUuid(shiftUuid);
  }

  const isShiftInPast = useCallback((event) => {
    const site = sitesList.find(site => site.uuid === event.site_uuid);
    return moment(event.date + ' ' + event.start_time).tz(site.time_zone_id).isBefore(moment());
  }, [sitesList]);

  const handleDeleteShift = useCallback( async (event) => {
    if (isShiftInPast(event)) {
      alert('You cannot delete a shift that has already started');
      return;
    }

    setShowTooltip(null);
    setConfirmDeleteModalProps({
      text: '',
      show: true,
      event,
    });
    setShowLoadingScreen(true);
    const shiftRes = await api.getCancellationPenaltyMessage(event.id);
    if (shiftRes.ok) {
      setConfirmDeleteModalProps({
        show: true,
        event,
        text: shiftRes.data.message,
      });
    }
    setShowLoadingScreen(false);
  }, [confirmDeleteModalProps, setConfirmDeleteModalProps, sitesList]);

  const handleConfirmDeleteShift = useCallback(async () => {
    setShowLoadingScreen(true);
    const shiftRes = await api.deleteBaristaShift({uuid: confirmDeleteModalProps.event.id});
    if (shiftRes.ok) {
      setShiftList(shiftList.filter(shift => shift.uuid !== confirmDeleteModalProps.event.id));
    } else {
      alert("Error: failed to delete shift. If this problem persists, please contact us.");
    }
    setConfirmDeleteModalProps(INITIAL_CONFIRM_DELETE_MODAL_PROPS);
    setShowLoadingScreen(false);
  }, [shiftList, confirmDeleteModalProps]);

  const getBaristaNameList = useCallback(
    (baristaUuidList) => {
      return baristasList.filter(
        barista => baristaUuidList.indexOf(barista.uuid) > -1
      ).map(
        (barista) => `${barista.first_name} ${barista.last_name}`
      ).join(', ');
    },
    [baristasList]
  );

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(event.status && {
        style: {
          opacity: view === 'agenda' ? 1 : STATUS[event.status],
          backgroundColor: view === 'agenda' ? 'white' : COLORS[sitesList.findIndex(site => site.uuid === event.site_uuid)],
        },
      }),
    }),
    [sitesList]
  )

  const syncShifts = async () => {
    const shiftRes = await api.getBaristaShiftList({
      start_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
    });
    if (shiftRes.ok) {
      setShiftList(shiftRes.data);
    } else {
      setShiftList([])
    }
  }

  useEffect(() => {
    if (user) {
      syncShifts();
    } else {
      setShiftList([])
    }
  }, [user]);

  const visibleEvents = shiftList.filter((shift) => {
    return appliedFilters.site.length === 0 ? true : appliedFilters.site.indexOf(shift.site.uuid) > -1
  }).filter((shift) => {
    return appliedFilters.baristas.length === 0 ? true : shift.baristas.length > 0 && appliedFilters.baristas.indexOf(shift.baristas[0].uuid) > -1
  }).filter((shift) => {
    return appliedFilters.status.length === 0 ? true : appliedFilters.status.indexOf(shift.status) > -1
  }).filter((shift) => {
    return appliedFilters.start_date === null ? true : moment(shift.date + ' 23:59') >= appliedFilters.start_date
  }).filter((shift) => {
    return appliedFilters.end_date === null ? true : moment(shift.date + ' 00:00') <= appliedFilters.end_date
  });

  return (
    <div style={{maxWidth: 1200}}>
      <EditShiftModal
        uuid={editingShiftUuid}
        onClose={() => setEditingShiftUuid(null)}
        onFinishUpdatingShift={syncShifts}
      />

      <ConfirmDeleteShiftModal
        open={confirmDeleteModalProps.show}
        text={confirmDeleteModalProps.text}
        onClose={() => setConfirmDeleteModalProps(INITIAL_CONFIRM_DELETE_MODAL_PROPS)}
        onConfirm={handleConfirmDeleteShift}
      >
        {confirmDeleteModalProps.event ? <EventSummary
          date={confirmDeleteModalProps.event.date}
          startTime={confirmDeleteModalProps.event.start_time}
          endTime={confirmDeleteModalProps.event.end_time}
          baristaName={confirmDeleteModalProps.event.barista_name}
          siteName={confirmDeleteModalProps.event.site_name}
          status={confirmDeleteModalProps.event.status}
        /> : null}
      </ConfirmDeleteShiftModal>
      <Grid container spacing={'10px'}>
        <Grid item xs={12}>
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              padding: '6px 10px',
              border: '2px solid #FF4B95',
              borderRadius: '10px',
              minHeight: '32px',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.17)',
              overflow: 'hidden',
            }}
            ref={filterRef}
            onClick={() => setShowFilters(true)}
          >
            <div><FilterIcon style={{color: "#FF4B95", marginRight: '5px'}} /></div>
            <div style={{
              display: 'flex',
              flex: 1,
              gap: '10px',
              fontWeight: 600,
              flexWrap: 'wrap',
            }}>
              {appliedFilters?.status.length > 0 && <Chip label={`Status: ${appliedFilters.status.join(', ')}`} />}
              {appliedFilters?.site.length > 0 && <Chip label={`Site: ${getSiteNameList(appliedFilters.site)}`} />}
              {appliedFilters?.start_date &&
                <Chip label={`Date from: ${moment(appliedFilters.start_date).format('DD/MM/YYYY')}`} />}
              {appliedFilters?.end_date &&
                <Chip label={`Date to: ${moment(appliedFilters.end_date).format('DD/MM/YYYY')}`} />}
              {appliedFilters?.baristas.length > 0 && <Chip label={`Baristas: ${getBaristaNameList(appliedFilters.baristas)}`} />}
            </div>
          </div>
          <Popover
            open={showFilters}
            anchorEl={filterRef && filterRef.current}
            onClose={() => setShowFilters(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 0,
              horizontal: 0
            }}
            PaperProps={{
              sx:{
                boxSizing: 'border-box',
                borderRadius: '0 0 10px 10px',
                border: '2px solid #FF4B95',
                borderTop: 0,
                paddingTop: '4px',
                marginTop: '-8px',
              },
              elevation: 0,
            }}
          >
            <div
              style={{
                width: filterRef?.current?.offsetWidth - 4,
                padding: '20px',
                boxSizing: 'border-box',
              }}
            >
              <div style={{display: 'flex'}}>
                <FormGroup sx={{marginRight: '20px'}}>
                  <div style={NotificationH1Style}>SITE</div>
                  {sitesList.map(site => (
                    <FormControlLabel
                      key={site.uuid}
                      label={site.name}
                      control={
                        <Checkbox
                          checked={appliedFilters.site.indexOf(site.uuid) > -1} onChange={() => {
                          setAppliedFilters({
                            ...appliedFilters,
                            site: appliedFilters.site.indexOf(site.uuid) > -1
                              ? appliedFilters.site.filter(i => i !== site.uuid)
                              : appliedFilters.site.concat(site.uuid)
                          })
                        }}
                        />
                      }
                    />
                  ))}
                </FormGroup>
                <FormGroup sx={{marginRight: '40px'}}>
                  <div style={NotificationH1Style}>DATE RANGE</div>
                  <div style={NotificationH3Style}>FROM</div>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={appliedFilters.start_date}
                    onChange={(newDate) => {
                      setAppliedFilters({
                        ...appliedFilters,
                        start_date: newDate,
                      })
                    }}
                    PopperProps={{
                      sx: {
                        '.MuiPaper-root > div > div': {
                          width: '420px',
                          maxHeight: '500px'
                        }
                      }
                    }}
                    renderInput={(params) => <TextField {...params} sx={{marginBottom: '10px', width: '160px'}} />}
                  />
                  <div style={NotificationH3Style}>TO</div>
                  <DesktopDatePicker
                    inputFormat="DD/MM/YYYY"
                    value={appliedFilters.end_date}
                    onChange={(newDate) => {
                      setAppliedFilters({
                        ...appliedFilters,
                        end_date: newDate,
                      })
                    }}
                    PopperProps={{
                      sx: {
                        '.MuiPaper-root > div > div': {
                          width: '420px',
                          maxHeight: '500px'
                        }
                      }
                    }}
                    renderInput={(params) => <TextField {...params} sx={{width: '160px'}} />}
                  />
                </FormGroup>
                <FormGroup sx={{marginRight: '20px'}}>
                  <div style={NotificationH1Style}>SHIFT STATUS</div>
                  {Object.keys(STATUS).map(status => (
                    <FormControlLabel
                      key={status}
                      label={status}
                      control={
                        <Checkbox
                          checked={appliedFilters.status.indexOf(status) > -1} onChange={() => {
                            setAppliedFilters({
                              ...appliedFilters,
                              status: appliedFilters.status.indexOf(status) > -1
                                ? appliedFilters.status.filter(i => i !== status)
                                : appliedFilters.status.concat(status)
                            })
                          }}
                        />
                      }
                    />
                  ))}
                </FormGroup>
                <FormGroup>
                  <div style={NotificationH1Style}>BARISTAS</div>
                  {baristasList.map(barista => (
                    <FormControlLabel
                      key={barista.uuid}
                      label={`${barista.first_name} ${barista.last_name}`}
                      control={
                        <Checkbox
                          checked={appliedFilters.baristas.indexOf(barista.uuid) > -1}
                          onChange={() => {
                            setAppliedFilters({
                              ...appliedFilters,
                              baristas: appliedFilters.baristas.indexOf(barista.uuid) > -1
                                ? appliedFilters.baristas.filter(i => i !== barista.uuid)
                                : appliedFilters.baristas.concat(barista.uuid)
                            })
                          }}
                        />
                      }
                    />
                  ))}
                </FormGroup>
              </div>
            </div>
          </Popover>
        </Grid>

        <Grid item xs={12}>
          <div style={{
            display: 'flex',
            marginBottom: '5px',
            flexWrap: 'wrap',
          }}>
            {sitesList.map((site, idx) => (
              <div
                style={{
                  display: 'flex',
                  marginRight: 10,
                  marginBottom: 5,
                  backgroundColor: COLORS[idx],
                  borderRadius: '100px',
                  fontWeight: 500,
                  fontSize: '14px',
                  color: 'white',
                  padding: '2px 8px',
                }}
                key={site.uuid}
              >
                <div>{site.name}</div>
              </div>
            ))}
          </div>
          <Paper sx={{height: 660, padding: '20px'}}>
            <Calendar
              localizer={localizer}
              date={date}
              view={view}
              events={visibleEvents.map(shift => {
                const barista_name = shift.baristas.length > 0 ? shift.baristas[0].first_name + ' ' + shift.baristas[0].last_name : 'No barista assigned';
                const blockBooking = shift.block_booking ? '[BB] ' : '';

                return {
                  date: shift.date,
                  start: moment(`${shift.date}T${shift.start_time}`).toDate(),
                  end: moment(`${shift.date}T${shift.end_time}`).toDate(),
                  start_time: shift.start_time,
                  end_time: shift.end_time,
                  id: shift.uuid,
                  title: blockBooking + shift.start_time + ' - ' + shift.end_time,
                  barista_name: barista_name,
                  status: shift.status,
                  site_uuid: shift.site.uuid,
                  site_name: shift.site.name,
                };
              })}
              eventPropGetter={eventPropGetter}
              components={{
                agenda: {
                  event: ({event, title}) => {
                    return (
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderRadius: '10px',
                        padding: '10px',
                        width: 500,
                        boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.15), inset 0px -6px 0px ${COLORS[sitesList.findIndex(site => site.uuid === event.site_uuid)]}`,
                      }}>
                        <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                          <EventSummary
                            date={event.date}
                            startTime={event.start_time}
                            endTime={event.end_time}
                            baristaName={event.barista_name}
                            siteName={event.site_name}
                            status={event.status}
                          />
                        </div>
                        <div style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-end',
                          paddingBottom: 5,
                        }}>
                          <Button
                            variant="contained-small"
                            style={{marginBottom: '10px', width: '90px'}}
                            disabled={isShiftInPast(event)}
                            onClick={() => handleEditShift(event.id)}
                          >Edit</Button>
                          <Button
                            variant="contained-small"
                            style={{width: '90px'}}
                            disabled={isShiftInPast(event)}
                            onClick={() => handleDeleteShift(event)}
                          >Delete</Button>
                        </div>
                      </div>
                    )
                  },
                },
                eventWrapper: ({event, children}) => (
                  <Tooltip
                    title={
                      <div style={{width: '100%', position: 'relative', paddingRight: '30px'}}>
                        <IconButton
                          onClick={() => setShowTooltip(null)}
                          variant={"outlined"}
                          sx={{
                            position: 'absolute',
                            right: '20px',
                            top: '-10px',
                          }}
                        >
                          <HighlightOffIcon fontSize="small" />
                        </IconButton>
                        <EventSummary
                          date={event.date}
                          startTime={event.start_time}
                          endTime={event.end_time}
                          baristaName={event.barista_name}
                          siteName={event.site_name}
                          status={event.status}
                        />
                        <div style={{marginBottom: 10}}>
                          <Button
                            fullWidth
                            color="primary"
                            variant="contained-small"
                            disabled={isShiftInPast(event)}
                            onClick={() => handleEditShift(event.id)}
                          >
                            Edit Shift
                          </Button>
                        </div>
                        <div>
                          <Button
                            fullWidth
                            color="primary"
                            variant="contained-small"
                            disabled={isShiftInPast(event)}
                            onClick={() => handleDeleteShift(event)}
                          >
                            Delete Shift
                          </Button>
                        </div>
                      </div>
                    }
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    open={showTooltip === event.id}
                    arrow
                  >
                    <div
                      onClick={() => {
                        if (showTooltip === event.id) {
                          setShowTooltip(null);
                        } else {
                          setShowTooltip(event.id)
                        }
                      }}
                    >
                      {children}
                    </div>
                  </Tooltip>
                )
              }}
              startAccessor="start"
              endAccessor="end"
              views={[Views.MONTH, Views.AGENDA]}
              onNavigate={onNavigate}
              onView={onView}
              onShowMore={(events, date) => {
                onNavigate(date);
                onView(Views.AGENDA);
              }}
              formats={{
                dateFormat: 'D',
                weekdayFormat: (date, culture, localizer) => localizer.format(date, 'dddd', culture),
                dayFormat: (date, culture, localizer) => localizer.format(date, 'dddd Do', culture),
                timeGutterFormat: (date, culture, localizer) => localizer.format(date, 'hh:mm a', culture),
                agendaDateFormat: (date, culture, localizer) => localizer.format(date, 'dddd Do', culture),
                agendaHeaderFormat: ({start, end}) => {
                  return (moment(start).format('dddd, Do MMM YYYY') + ' - ' + moment(end).format('dddd, Do MMM YYYY') );
                }
              }}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardPage;
