import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import siteLogo from './site-logo.svg';
import MenuIcon from 'mdi-material-ui/Menu';
import UserDropdown from './ProfileDropdown';
import {useRecoilValue} from "recoil";
import {userState} from "../../../../services/GlobalState";

const AppBarContent = props => {
  // ** Props
  const { hidden, settings, toggleNavVisibility } = props
  const user = useRecoilValue(userState);

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' onClick={toggleNavVisibility} sx={{
            opacity: user?.customer === null ? 0 : 1,
          }}>
            <MenuIcon />
          </IconButton>
        ) : <img src={siteLogo} width={45} />}
      </Box>
      {hidden && <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={siteLogo} width={45} style={{marginLeft: 20, marginRight: 20}} />
      </Box>}
      <Box className='actions-right' sx={{ display: 'flex', alignItems: 'center' }}>
        {/*<NotificationDropdown settings={settings} />*/}
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  )
}

export default AppBarContent
