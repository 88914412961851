import {useEffect, useState} from "react";
import {Container, Grid, Card} from "@mui/material";
import Api from 'src/services/Api';
import {NotificationH1Style} from "src/utils/styles";
import PhoneIcon from 'mdi-material-ui/PhoneOutline'
import MailIcon from 'mdi-material-ui/EmailOutline'


function ContactUsPage() {
  return (
    <div style={{maxWidth: '1200px'}}>
      <Grid container spacing="20px">
        <Grid item xs={6}>
          <Card sx={{padding: '15px 20px 20px 20px'}}>
            <div style={NotificationH1Style}>Urgent Queries</div>
            <div style={{fontSize: '14px'}}>
              For questions about shifts in the next <b>24 hours</b>,
              please call us on:
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
              <PhoneIcon sx={{fontSize: '34px', marginRight: '10px', marginTop: '4px'}} />
              <div style={{fontSize: '24px', fontWeight: 700}}>UK (+44) 1 212 852 721</div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{padding: '15px 20px 20px 20px'}}>
            <div style={NotificationH1Style}>Non-urgent Queries</div>
            <div style={{fontSize: '14px'}}>
              For all other questions, please email us on:
            </div>
            <div style={{display: 'flex', alignItems: 'center', marginTop: '20px'}}>
              <MailIcon sx={{fontSize: '34px', marginRight: '10px', marginTop: '4px'}} />
              <div style={{fontSize: '24px', fontWeight: 700}}>support@baristasontap.com</div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactUsPage;
