import {useEffect, useState} from "react";
import {Container, Grid, Card, Button} from "@mui/material";
import Api from 'src/services/Api';
import SplitLayout from "src/layouts/components/SplitLayout";
import {addOrRemove, getCurrency, round, sleep} from 'src/utils';
import ListItem from 'src/layouts/components/ListItem';
import {NotificationH1Style, NotificationH2Style} from "src/utils/styles";
import moment from 'moment';
import {useRecoilValue} from "recoil";
import {userState} from "src/services/GlobalState";

function InvoicePage() {
  const api = Api.create()
  const user = useRecoilValue(userState);
  const [list, setList] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const currency = getCurrency(user.country);

  useEffect(() => {
    (async () => {
      const shiftRes = await api.getInvoiceList();
      if (shiftRes.ok) {
        setList(shiftRes.data);
      } else {
        setList([])
      }
    })();
  }, [])

  return (
    <SplitLayout
      leftPanel={
      <div>
        {list.map(inv => (
          <ListItem
            key={inv.uuid}
            title={currency + inv.total}
            subtitle={`${moment(inv.issue_date).format('DD-MM-YYYY')}${inv.xero_invoice_status && ' - ' + inv.xero_invoice_status}`}
            isSelected={selectedInvoices.includes(inv)}
            onSelect={() => setSelectedInvoices(addOrRemove(selectedInvoices, inv))}
          />
        ))}
      </div>
      }
      rightPanel={
      <div>
        {selectedInvoices.length > 0 && <Card sx={{padding: '15px 20px 20px 20px'}}>
          <div style={NotificationH1Style}>{selectedInvoices.length} invoices selected</div>
          <div style={{...NotificationH2Style, marginTop: 20, marginBottom: 0}}>Total amount</div>
          <div style={{
            fontWeight: 700,
            fontSize: '32px',
            marginBottom: '20px',
          }}>
            {currency}{selectedInvoices.reduce((acc, inv) => acc + round(parseFloat(inv.total), 2), 0)}
          </div>
          <div>
            <Button fullWidth variant="contained" onClick={async () => {
              for (const inv of selectedInvoices) {
                window.open(api.getDownloadFileUrl(inv.uuid), '_blank');
                await sleep(1000);
              }
            }}>DOWNLOAD INVOICES</Button>
          </div>
        </Card>}
      </div>
      }
    />
  )
}

export default InvoicePage;
