// ** MUI Imports
import Divider from '@mui/material/Divider'
import { styled, useTheme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiListSubheader from '@mui/material/ListSubheader'

// ** Custom Components Imports
import Translations from 'src/layouts/components/Translations'
import CanViewNavSectionTitle from 'src/layouts/components/acl/CanViewNavSectionTitle'

// ** Styled Components
const ListSubheader = styled(props => <MuiListSubheader component='li' {...props} />)(({ theme }) => ({
  lineHeight: 1,
  display: 'flex',
  position: 'static',
  marginTop: '20px',
  marginBottom: '5px',
  backgroundColor: 'transparent'
}))

const TypographyHeaderText = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
  lineHeight: 'normal',
  letterSpacing: '0.21px',
  textTransform: 'uppercase',
  color: theme.palette.text.disabled,
  fontWeight: theme.typography.fontWeightMedium
}))

const VerticalNavSectionTitle = props => {
  // ** Props
  const { item, navHover, hidden } = props

  return (
    <div>
      <ListSubheader
        className='nav-section-title'
        sx={{
          opacity: !navHover && !hidden ? '0' : '1',
          paddingLeft: '20px',
        }}
      >
        <TypographyHeaderText noWrap sx={{
          color: '#344534',
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '22px',
        }}>
          <Translations text={item.sectionTitle} />
        </TypographyHeaderText>
      </ListSubheader>
    </div>
  )
}

export default VerticalNavSectionTitle
