import React, {useCallback, useState} from 'react';
import {Link} from 'react-router-dom';
import {
  Button,
  FormControl,
  OutlinedInput,
  Grid,
  Card,
  CardHeader,
  IconButton,
  Alert,
  CardContent,
  FormHelperText,
  Box,
  Tooltip,
  Chip,
} from "@mui/material";
import _ from 'lodash';
import Moment from 'moment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useAppData from "src/contexts/AppData";
import TimePicker from 'src/layouts/components/TimePicker';
import {baristaToString, formatShiftData, DAY_MAPPING, NOTIFY_TO_STR_MAPPING} from "src/utils";
import Api from "src/services/Api";
import {NotificationH3Style, NotificationH1Style, PillStyle} from "src/utils/styles";


const BookShiftForm = ({
                         value, hideDelete, onDelete, onChange, onChangeEstimate, onSelect, color, currency, isExpanded=false, isEdit=false
                       }) => {
  const [errors, setErrors] = useState({});
  const [baristaList, setBaristaList] = useState([]); // [barista_uuid, ...
  const api = Api.create();
  const {siteList} = useAppData();
  let Content = null;

  React.useEffect(() => {
    const dateCsvStr = value.date_list?.join(',');
    if (value.estimate !== null && dateCsvStr) {
      api.getBaristaList({date_csv: dateCsvStr}).then(({ ok, data }) => {
        if (ok) {
          setBaristaList(data);
        }
      });
    }
  }, [value.estimate, value.date_list]);

  const days_list = _.uniq(
    value.date_list.map(
      dateStr => DAY_MAPPING[Moment(dateStr).isoWeekday() - 1],
    ),
  );

  const minWage = _.min(value.estimate?.per_hour_wage || [0]);

  const handleChangeBarista = (event) => {
    const {
      target: { value },
    } = event;
    onChange({
      barista_uuid: typeof value === 'string' ? value.split(',') : value
    }, false);
  };

  const getSiteName = (siteUuid) => {
    if (siteUuid) {
      const site = siteList.find(site => site.uuid === siteUuid);
      return site ? site.name : '';
    }
    return 'No site selected';
  }

  const getEstimate = useCallback(async () => {
    onChangeEstimate({
      estimate: null,
    });
    setErrors({});
    const estimate = await api.estimateCost(formatShiftData(value));
    if (estimate.status === 200) {
      onChangeEstimate({
        estimate: estimate.data,
      });
    } else {
      setErrors(estimate.data);
    }
  }, [value, onChange]);

  const getBaristaLabel = useCallback((baristaUuid) => {
    const barista = baristaList.find(b => b.uuid === baristaUuid);
    return baristaToString(barista);
  }, [baristaList]);

  if (isExpanded) {
    Content = (
      <Grid container spacing={'20px'}>
        <Grid item xs={12}>
          <div style={NotificationH1Style}>SHIFT TIME</div>
          <div style={{display: 'flex'}}>
            <div style={{marginRight: 20}}>
              <div style={NotificationH3Style}>From</div>
              <TimePicker
                value={value.start_time}
                onChange={(start_time) => onChange({start_time})}
                error={errors?.date_list && errors.date_list[0].start_time && errors.date_list[0].start_time}
              />
            </div>
            <div>
              <div style={NotificationH3Style}>To</div>
              <TimePicker
                value={value.end_time}
                onChange={(end_time) => onChange({end_time})}
                error={errors?.date_list && errors.date_list[0].end_time && errors.date_list[0].end_time}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={NotificationH1Style}>SITE</div>
          <FormControl sx={{width: '100%'}} error={errors?.site}>
            <Select
              error={errors?.site}
              value={value.site}
              onChange={(evt) => onChange({site: evt.target.value})}
              fullWidth
            >
              {siteList.map(site => (
                <MenuItem key={site.uuid} value={site.uuid}>{site.name}</MenuItem>
              ))}
            </Select>
            {errors?.site && <FormHelperText>{errors.site}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <div style={NotificationH1Style}>BARISTA FEE BOOST</div>
          <FormControl fullWidth error={errors?.date_list && errors.date_list[0].boost}>
            <Select
              error={errors?.date_list && errors.date_list[0].boost}
              value={value.boost}
              onChange={(evt) => onChange({boost: evt.target.value})}
              fullWidth
            >
              {[...Array(20).keys()].map(x => (
                <MenuItem key={x} value={x * 0.5}>
                  + {currency}{x * 0.5}
                </MenuItem>
              ))}
            </Select>
            {errors?.date_list && errors.date_list[0].boost && <FormHelperText>{errors.date_list[0].boost}</FormHelperText>}
          </FormControl>
        </Grid>
        {!isEdit && (
          <Grid item xs={6}>
            <div style={NotificationH1Style}>How many baristas</div>
            <FormControl fullWidth error={errors?.num_barista}>
              <OutlinedInput
                fullWidth
                type={'number'}
                value={value.num_barista}
                error={errors?.num_barista}
                onChange={(evt) => onChange({num_barista: evt.target.value})}
              />
              {errors?.num_barista && <FormHelperText>{errors?.num_barista}</FormHelperText>}
            </FormControl>
          </Grid>
        )}
      </Grid>
    );
  } else {
    Content = (
      <div style={{fontSize: '15px'}}>
        <div style={NotificationH1Style}>
          {value.start_time?.format('h:mm a')} - {value.end_time?.format('h:mm a')}
        </div>
        <div>{getSiteName(value.site)}, {value.date_list.length} shifts, +{currency}{value.boost}/hour boost, {value.num_barista} barista(s)</div>
      </div>
    )
  }

  return (
    <Card elevation={isEdit ? 0 : 3} sx={
      isEdit ? {} : {marginBottom: '20px', width: '100%', position: 'relative', paddingTop: '20px'}}>
      <div style={{height: '20px', backgroundColor: color, width: '100%', position: 'absolute', top: 0, left: 0}} />
      <CardHeader
        action={
          !hideDelete && <IconButton onClick={onDelete} variant={"outlined"}>
            <HighlightOffIcon />
          </IconButton>
        }
        sx={{position: 'absolute', top: '10px', right: '0'}}
      />
      <CardContent sx={isEdit ? { padding: 0 } : {padding: '20px !important'}}>
        {Content}
        <div style={{marginTop: 20}}>
          {value.estimate ? (
            <>
              {!isEdit && (<>
                <div style={NotificationH1Style}>REQUEST BARISTA</div>
                <Alert severity="info" sx={{marginBottom: 1}}>
                  Only baristas who have no shifts on the days you have selected will be visible.
                </Alert>
                <FormControl sx={{width: '100%'}}>
                  <Select
                    fullWidth
                    multiple
                    value={value.barista_uuid}
                    onChange={handleChangeBarista}
                    renderValue={(selected) => {
                      return (
                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                          {selected.map((selectedBaristaUuid) => (
                            <Chip
                              key={selectedBaristaUuid}
                              label={getBaristaLabel(selectedBaristaUuid)}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              onDelete={() => {
                                handleChangeBarista({
                                  target: {
                                    value: value.barista_uuid.filter(
                                      (baristaUuid) => baristaUuid !== selectedBaristaUuid
                                    )
                                  }
                                })
                              }}
                            />
                          ))}
                        </Box>
                      )
                    }}
                  >
                    {baristaList.map(barista => {
                      const isBaristaAvailable = days_list.every(day => {
                        return barista[day] === true;
                      });
                      const isWageEnough = minWage >= barista.min_rate;
                      const daysUnavailable = days_list
                        .filter(day => (barista[day] === false))
                        .map(day => (NOTIFY_TO_STR_MAPPING[day]));

                      if (!isBaristaAvailable || !isWageEnough) {
                        return (
                          <MenuItem key={barista.uuid} value={barista.uuid} disabled sx={{display: 'block'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                              <div>{baristaToString(barista)}</div>
                              {!isWageEnough && <div>Min rate: {barista.min_rate}</div>}
                            </div>
                            <div>
                              Not available on {daysUnavailable.join(', ')}
                            </div>
                          </MenuItem>
                        )
                      }

                      return (
                        <MenuItem key={barista.uuid} value={barista.uuid} disabled={!isBaristaAvailable || !isWageEnough}>
                          {baristaToString(barista)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormHelperText sx={{textAlign: 'right'}}>
                  Don’t see the barista you’re looking for? Add them to <Link to={'/barista'}><b>Your Baristas</b></Link>
                </FormHelperText>
              </>)}
              <div style={{textAlign: 'center', marginTop: 20}}>
                <div style={{...NotificationH3Style, textTransform: 'none'}}>
                  Estimated cost
                </div>
                <div style={NotificationH1Style}>
                  <div style={PillStyle}>{currency}{value.estimate.price}</div>
                </div>
                <Alert severity="info" sx={{marginBottom: 1, whiteSpace: 'pre-line', textAlign: 'left'}}>
                  {value.estimate.breakdown}
                </Alert>
              </div>
            </>
          ) : (
            <FormControl error={!!errors} sx={{width: '100%'}}>
              <Button
                variant='contained'
                fullWidth
                disabled={value.date_list.length === 0}
                onClick={() => getEstimate()}
              >GET ESTIMATE
              </Button>
              <FormHelperText>
                {value.date_list?.map(date => errors[date])}
                {value.date_list.length === 0 && "Please select one or more dates."}
              </FormHelperText>
            </FormControl>
          )}
        </div>
        {!isExpanded && <>
          <div style={{height: 50}} />
          <div style={{textAlign: 'right', position: 'absolute', right: 20, bottom: 20}}>
            <Button
              variant="outlined"
              onClick={onSelect}
            >EDIT</Button>
          </div>
        </>}
      </CardContent>
    </Card>
  )
}

export default BookShiftForm;
