import React, {useCallback, useEffect, useState} from 'react';
import {Controller, useForm, useFieldArray} from "react-hook-form";
import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import moment from 'moment';
import SplitLayout from "src/layouts/components/SplitLayout";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import BookShiftForm from '../components/BookShiftForm';
import _ from 'lodash';
import Api from "src/services/Api";
import {formatShiftData, getCurrency} from "src/utils";
import {NotificationH1Style, NotificationH2Style, NotificationH3Style, PillStyle} from "src/utils/styles";
import {useNavigate} from "react-router-dom";
import useAppData from "src/contexts/AppData";
import {useRecoilValue} from "recoil";
import {userState} from "src/services/GlobalState";


const INITIAL_SHIFT_VALUE = {
  start_time: moment().set({hour: 9, minute: 0}),
  end_time: moment().set({hour: 17, minute: 0}),
  date_list: [],
  boost: 0,
  site: '',
  num_barista: 1,
  barista_uuid: [],
}

const COLORS = ['#FFA600', '#FF6361', '#BC5090', '#58508D', '#003F5C', '#94CE66', '#296698', '#E8E192', '#B390FA',];

const BookShiftPage = () => {
  const api = Api.create();
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const [showCreateShiftResults, setShowCreateShiftResults] = useState(false);
  const [createShiftResults, setCreateShiftResults] = useState([]);
  const [selectedShift, setSelectedShift] = useState(0);
  const [currentDate, setCurrentDate] = useState(moment());
  const {setShowLoadingScreen} = useAppData();
  const currency = getCurrency(user.country);

  const {reset, watch, handleSubmit, control, setValue, formState: {errors}} = useForm({
    defaultValues: {
      shiftGroup: [INITIAL_SHIFT_VALUE]
    }
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "shiftGroup", // unique name for your Field Array
  });

  const watchedShiftGroup = watch('shiftGroup');

  const onSubmit = async data => {
    setShowLoadingScreen(true);
    const createShiftResponseData = [];
    for (const shiftGroupIdx in data.shiftGroup) {
      const shiftGroup = data.shiftGroup[shiftGroupIdx];
      const formData = formatShiftData(shiftGroup);
      const postShiftRes = await api.postBaristaShift(formData);
      if (postShiftRes.status === 200) {
        createShiftResponseData.push(postShiftRes.data);
      } else {
        createShiftResponseData.push({
          'heading': 'SHIFT CREATION FAILED',
          'subheading': 'There was an issue when attempting to create the shifts.',
          'notification_text': 'Please contact support@milk-nosugar.com for more details.'
        })
      }
    }
    setCreateShiftResults(createShiftResponseData);
    setShowCreateShiftResults(true);
    setShowLoadingScreen(false);
  };

  const allShiftDates = _.map(watchedShiftGroup, 'date_list');
  const validShiftDates = _.map(_.filter(watchedShiftGroup, (fieldData) => fieldData.estimate !== null), 'date_list');

  const getShiftIndex = useCallback((dateToFind) => {
    return allShiftDates.map((shiftDates) => (shiftDates.indexOf(dateToFind.format('YYYY-MM-DD')) > -1));
  }, [fields]);

  const addShift = useCallback(() => {
    append(INITIAL_SHIFT_VALUE);
    setSelectedShift(fields.length);
  }, [fields]);

  const deleteShift = useCallback((idxToRemove) => {
    remove(idxToRemove);
  }, [fields]);

  const toggleDateOnShift = useCallback((dateToAdd) => {
    let newDateList;

    if (fields[selectedShift].date_list.includes(dateToAdd.format('YYYY-MM-DD'))) {
      newDateList = fields[selectedShift].date_list.filter(
        (shiftDate) => shiftDate !== dateToAdd.format('YYYY-MM-DD')
      );
    } else {
      newDateList = _.uniq([...fields[selectedShift].date_list, dateToAdd.format('YYYY-MM-DD')]);
    }
    update(selectedShift, {
      ...watchedShiftGroup[selectedShift],
      barista_uuid: [],
      date_list: newDateList,
      estimate: null,
    });
  }, [fields, watchedShiftGroup, selectedShift]);

  const costMapping = watchedShiftGroup.map((shiftGroup) => {
    const retVal = {};
    shiftGroup.date_list.forEach((date, idx) => {
      retVal[date] = shiftGroup.estimate?.per_hour_wage[idx] || '???';
    });
    return retVal;
  });

  const getDayStyle = (shiftDateMapping) => {
    const selectedShiftCount = shiftDateMapping.filter((shiftHasDate) => shiftHasDate).length;
    if (selectedShiftCount < 1) {
      return {};
    }
    const totalMappingsPercentRatio = 100 / selectedShiftCount;
    const coloursSelected = COLORS.filter((color, idx) => shiftDateMapping[idx]);
    return {
      background: `conic-gradient(${
        coloursSelected.map((color, idx) =>
          `${color} ${totalMappingsPercentRatio * idx}%  ${totalMappingsPercentRatio * (idx + 1)}%`).join(', ')
      });`,
      color: 'white',
    }
  }

  const getTotalEstimate = () => {
    var totalCost = 0;
    for (const groupIdx in watchedShiftGroup) {
      const shiftGroup = watchedShiftGroup[groupIdx];
      if (shiftGroup.estimate) {
        totalCost += parseFloat(shiftGroup.estimate.price);
      } else {
        totalCost = '???';
        break;
      }
    }
    return totalCost !== '???' ? totalCost.toFixed(2) : totalCost;
  }

  const totalEstimate = getTotalEstimate();

  if (user?.is_disabled) {
    return (
      <Alert severity="warning" sx={{marginTop: 2}}>
        You cannot book shifts at this time.<br />
        Reason: {user?.disabled_reason}
      </Alert>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog
        open={showCreateShiftResults}
        onClose={() => setShowCreateShiftResults(false)}
      >
        <DialogTitle sx={NotificationH1Style}>
          CREATE SHIFT RESULTS
        </DialogTitle>
        <DialogContent>
          {createShiftResults.map((createShiftResultItem, idx) => (
            <div
              key={idx}
                style={{
                whiteSpace: 'pre-line',
                marginBottom: '20px'
              }}
            >
              <DialogContentText sx={{
                ...NotificationH2Style,
                padding: '8px 15px',
                backgroundColor: COLORS[idx],
                color: 'white',
                borderRadius: '10px 10px 0 0',
              }}>
                {createShiftResultItem.heading}
              </DialogContentText>
              <DialogContentText sx={{
                padding: '8px 15px',
                fontSize: '15px',
                lineHeight: '17px',
              }}>
                <b>{createShiftResultItem.subheading}</b><br/><br/>
                {createShiftResultItem.notification_text}
              </DialogContentText>
            </div>
          ))}
        </DialogContent>
        <div style={{padding: '0 20px 20px 20px',}}>
          <Button
            fullWidth
            variant="outlined"
            sx={{marginBottom: '10px'}}
            onClick={() => navigate('/')}
          >VIEW SHIFTS</Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              setShowCreateShiftResults(false);
              reset();
            }}
          >CREATE MORE SHIFTS</Button>
        </div>
      </Dialog>

      <SplitLayout
        leftPanel={
        <div>
          {fields.map((shiftGroupRow, idx) => {
            return (
              <Controller
                key={shiftGroupRow.id}
                control={control}
                name={`shiftGroup.${idx}`}
                defaultValue={INITIAL_SHIFT_VALUE}
                render={({field: {onChange, value}}) => {
                  return (
                    <BookShiftForm
                      value={value}
                      hideDelete={idx === 0}
                      isExpanded={selectedShift === idx}
                      onSelect={() => setSelectedShift(idx)}
                      onDelete={() => deleteShift(idx)}
                      onChange={(newValue, clearEstimate=true) => onChange({
                        ...value,
                        ...newValue,
                        barista_uuid: clearEstimate ? [] : newValue.barista_uuid,
                        estimate: clearEstimate ? null : value.estimate,
                      })}
                      onChangeEstimate={(newValue) => onChange({...value, ...newValue})}
                      color={COLORS[idx]}
                      currency={currency}
                    />
                  )
                }}
              />
            )})
          }
          <div style={{textAlign: 'center'}}>
            <Button onClick={addShift} variant='contained' disabled={fields.length >= COLORS.length}>
              + ADD MORE
            </Button>
          </div>
        </div>
        }
        rightPanel={
        <div>
          <Card sx={{padding: '20px'}} elevation={3}>
            <div style={NotificationH1Style}>Select Dates</div>
            <div style={{...NotificationH3Style, textTransform: 'none'}}>
              You can select as many dates as you would like to create shifts for the details on the left
            </div>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              onChange={(newDate) => setCurrentDate(newDate)}
              value={currentDate}
              minDate={moment()}
              disablePast
              renderInput={(params) => <TextField {...params} />}
              renderDay={(date, selectedDays, dateRangePickerDayProps) => {
                const coloursSelected = COLORS[selectedShift];
                console.log(selectedShift)
                return (
                  <div
                    key={dateRangePickerDayProps.key}
                    onClick={() => {
                      if (dateRangePickerDayProps.disabled) {
                        return;
                      }
                      toggleDateOnShift(date)
                    }}
                    style={{
                      visibility: dateRangePickerDayProps.disabled ? 'hidden' : 'visible',
                      opacity: dateRangePickerDayProps.outsideCurrentMonth ? 0.5 : 1,
                    }}
                  >
                    <PickersDay
                      {...dateRangePickerDayProps}
                      outsideCurrentMonth={false}
                      selected={false}
                      sx={getDayStyle(getShiftIndex(date))}
                    />
                    <div style={{textAlign: 'center', fontSize: 14, marginTop: -2, color: coloursSelected, fontWeight: 500}}>
                      &nbsp;{(costMapping[selectedShift] && costMapping[selectedShift][date.format('YYYY-MM-DD')]) ?
                        `${currency}${costMapping[selectedShift][date.format('YYYY-MM-DD')]}` : null}&nbsp;
                    </div>
                  </div>
                );
              }}
            />
            <div style={{textAlign: 'center'}}>
              <div style={{...NotificationH3Style, textTransform: 'none'}}>
                Estimated cost
              </div>
              <div style={NotificationH1Style}>
                <div style={PillStyle}>{currency}{totalEstimate}</div>
              </div>
            </div>
          </Card>
          <div style={{marginTop: 20}}>
            <Button
              onClick={handleSubmit(onSubmit)}
              variant='contained'
              disabled={user.customer?.requires_billing_details || user.is_disabled || totalEstimate === '???'}
              sx={{width: '100%'}}
            >
              CREATE {_.flatten(validShiftDates).length} SHIFTS
            </Button>
            {user.customer?.requires_billing_details && (
              <Alert severity="warning" sx={{marginTop: 2}}>
                You can't book a barista until you've set up Billing Details.<br />
                <a href={`${process.env.REACT_APP_BASE_API_DOMAIN}/api/bot_billing/v1/billing_request/`}>
                  Click here to set up Billing Details
                </a>.
              </Alert>
            )}
          </div>
        </div>
        }
      />
    </form>
  )
}

export default BookShiftPage;
