import {atom, selector} from "recoil";


export const userState = atom({
  key: 'User',
  default: null,
})

export const userSelector = selector({
  key: 'ProxySelector',
  get: ({get}) => {
    // @TODO add support for baristas
    const user = get(userState);
    if (user) {
      return ({
        ...user,
        displayName: user.customer ? user.customer.contact_name : ''
      })
    }
    return {}
  }
});

export const shiftListState = atom({
  key: 'ShiftList',
  default: [],
})