import Badge from '@mui/material/Badge';
import BellOutline from 'mdi-material-ui/BellOutline'
import CalendarBlankOutline from 'mdi-material-ui/CalendarBlankOutline'
import PhoneOutline from 'mdi-material-ui/PhoneOutline'
import ScriptTextOutline from 'mdi-material-ui/ScriptTextOutline'
import ClipboardAccountOutline from 'mdi-material-ui/ClipboardAccountOutline'
import StoreOutline from 'mdi-material-ui/StoreOutline'
import PlusCircleOutline from 'mdi-material-ui/PlusCircleOutline'
import useAppData from "src/contexts/AppData";

const NotificationIcon = () => {
  const {notificationList} = useAppData();
  const numUnread = notificationList.filter((item) => !item.is_read).length;
  if (numUnread > 0) {
    return (
      <Badge badgeContent={numUnread} color="primary">
        <BellOutline />
      </Badge>
    )
  }
  return (
    <BellOutline />
  )
}

const navigation = () => {

  return [
    {
      sectionTitle: 'Notifications'
    },
    {
      title: 'View Notifications',
      icon: NotificationIcon,
      path: '/notification'
    },
    {
      sectionTitle: 'Shifts'
    },
    {
      title: 'Create New Shifts',
      icon: PlusCircleOutline,
      path: '/shift'
    },
    {
      title: 'Manage Existing Shifts',
      icon: CalendarBlankOutline,
      path: '/'
    },
    {
      sectionTitle: 'Sites'
    },
    {
      title: 'Manage Sites',
      icon: StoreOutline,
      path: '/site'
    },
    {
      sectionTitle: 'Baristas'
    },
    {
      title: 'Your Baristas',
      icon: ClipboardAccountOutline,
      path: '/barista'
    },
    {
      sectionTitle: 'Invoices'
    },
    {
      title: 'View Invoices',
      icon: ScriptTextOutline,
      path: '/invoice'
    },
    {
      title: 'Contact Us',
      icon: PhoneOutline,
      path: '/contact-us'
    },
  ]
}

export default navigation
