import React from "react";
import {Card, Button, OutlinedInput} from "@mui/material";
import SplitLayout from "src/layouts/components/SplitLayout";
import useAppData from "src/contexts/AppData";
import ListItem from "src/layouts/components/ListItem";
import TrashIcon from 'mdi-material-ui/TrashCanOutline'
import AccountPlusOutline from 'mdi-material-ui/AccountPlusOutline'
import {NotificationH1Style, NotificationH2Style, NotificationH3Style} from 'src/utils/styles';
import ConfirmModal from "src/layouts/components/ConfirmModal";
import Api from "src/services/Api";

function BaristaPage() {
  const api = Api.create();
  const {baristaList, removeBaristaFromFavourites, addBaristaToFavourites} = useAppData();
  const [selectedBarista, setSelectedBarista] = React.useState(null);
  const [searchBaristaList, setSearchBaristaList] = React.useState(null);
  const [searchBaristaQuery, setSearchBaristaQuery] = React.useState('');

  const searchBarista = React.useCallback((searchTerm) => {
    api.getBaristaList({q: searchTerm}).then(({ ok, data }) => {
      if (ok) {
        setSearchBaristaList(data);
      } else {
        setSearchBaristaList([]);
      }
    });
  }, [searchBaristaQuery]);

  return (
    <SplitLayout
      leftPanel={
        <div>
          <ConfirmModal
            title={`REMOVE ${selectedBarista?.first_name}?`}
            confirmButtonText="REMOVE BARISTA"
            open={selectedBarista !== null}
            onClose={() => setSelectedBarista(null)}
            onConfirm={() => {
              removeBaristaFromFavourites(selectedBarista.uuid);
              setSelectedBarista(null);
            }}
          >
            You can add this barista back at any time using the search feature if you change your mind.
          </ConfirmModal>

          {baristaList.map(barista => (
            <ListItem
              key={barista.uuid}
              title={`${barista.first_name} ${barista.last_name}`}
              icon={<TrashIcon onClick={() => setSelectedBarista(barista)} />}
            />
          ))}
          <div style={{...NotificationH3Style, textTransform: 'none'}}>
            Your baristas are the baristas that you can request when creating shifts. By default, any barista that has
            taken a shift with you previously will be added to this list, but you can remove baristas using the bin
            icon, and add new baristas using the search feature on the right.
          </div>
        </div>
      }
      rightPanel={
        <div>
          <Card elevation={3} sx={{padding: '15px 20px', marginBottom: '20px'}}>
            <div style={NotificationH1Style}>Add barista</div>
            <div style={{...NotificationH3Style, textTransform: 'none'}}>Search for a Barista to add them to your roster</div>
            <div style={{margin: '15px 0'}}>
              <OutlinedInput
                type="text"
                onChange={(evt) => {
                  setSearchBaristaList(null);
                  setSearchBaristaQuery(evt.target.value);
                }}
                value={searchBaristaQuery}
                fullWidth
              />
              {searchBaristaQuery.length < 3 && <div style={{...NotificationH3Style, textTransform: 'none', textAlign: 'center', marginTop: '2px'}}>
                Search term must be at least 3 characters
              </div>}
            </div>
            <Button
              disabled={searchBaristaQuery.length < 3}
              variant="contained"
              fullWidth
              onClick={() => searchBarista(searchBaristaQuery)}
            >
              SEARCH
            </Button>
          </Card>
          {searchBaristaList !== null && searchBaristaList.length === 0 && (
            <div style={{...NotificationH2Style, textAlign: 'center', textTransform: 'none'}}>
              No results found for '{searchBaristaQuery}'
            </div>
          )}
          {searchBaristaList && searchBaristaList.map(barista => (
            !baristaList.find(bar => bar.uuid === barista.uuid) && <ListItem
              key={barista.uuid}
              title={`${barista.first_name} ${barista.last_name}`}
              icon={<AccountPlusOutline onClick={() => addBaristaToFavourites(barista.uuid)} />}
            />
          ))}
        </div>
      }
    />
  )
}

export default BaristaPage;
