import React from "react";
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {NotificationH1Style, NotificationH2Style} from "src/utils/styles";


const ConfirmModal = ({open, onClose, onConfirm, title, confirmButtonText='CONFIRM', children}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle sx={NotificationH1Style}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            // ...NotificationH2Style,
            fontSize: '16px',
            whiteSpace: 'pre-line',
            marginBottom: '20px',
          }}
        >
          {children}
        </DialogContentText>
      </DialogContent>
      <div style={{padding: '0 20px 20px 20px',}}>
        <Button
          fullWidth
          variant="outlined"
          sx={{marginBottom: '10px'}}
          onClick={onClose}
        >CANCEL</Button>
        <Button
          fullWidth
          variant="contained"
          onClick={onConfirm}
        >{confirmButtonText}</Button>
      </div>
    </Dialog>
  )
}

export default ConfirmModal;