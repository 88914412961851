import Api from 'src/services/Api';
import React, {useEffect} from "react";
import {useRecoilState} from 'recoil';
import {userState} from "src/services/GlobalState";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import DashboardPage from 'src/pages/DashboardPage';
import NotificationPage from 'src/pages/NotificationPage';
import InvoicePage from 'src/pages/InvoicePage';
import BookShiftPage from 'src/pages/BookShiftPage';
import SitePage from 'src/pages/SitePage';
import ContactUsPage from 'src/pages/ContactUsPage';
import BaristaPage from 'src/pages/BaristaPage';

function App() {
  const api = Api.create()
  const [user, setUser] = useRecoilState(userState);

  useEffect(() => {
    (async () => {
      const whoAmI = await api.whoAmI();
      if (whoAmI.ok) {
        setUser(whoAmI.data);
      } else {
        setUser(null);
        window.location.href = `${process.env.REACT_APP_LOGIN_URL}/?next=${window.location.href}`;
      }
    })();
  }, []);

  const logout = () => {
    setUser(null);
    api.logout().then(() => window.location.reload());
  }

  if (user === null) {
    return (<div>Loading ...</div>)
  }

  if (user.customer === null) {
    return (
      <div style={{textAlign: 'center'}}>
        <h3>Customer account not found</h3>
        <p style={{fontWeight: 500}}>
          Please{' '}
          <span style={{color: 'blue', textDecoration: 'underline'}} onClick={logout}>
            log in
          </span>{' '}
          with your customer account to continue.
        </p>
      </div>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<DashboardPage/>}/>
      <Route path="/notification" element={<NotificationPage/>}/>
      <Route path="/invoice" element={<InvoicePage/>}/>
      <Route path="/shift" element={<BookShiftPage/>}/>
      <Route path="/site" element={<SitePage/>}/>
      <Route path="/contact-us" element={<ContactUsPage/>}/>
      <Route path="/barista" element={<BaristaPage/>}/>
    </Routes>
  );
}

export default App;
