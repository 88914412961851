import React, { Component } from 'react'
import {NotificationH1Style} from "src/utils/styles";


class NotificationView extends Component {
  render () {
    const {heading, subheading, notificationText} = this.props

    return (
      <div>
        <div style={NotificationH1Style}>{heading}</div>
        <div style={{fontWeight: 600, marginBottom: 10}}>{subheading}</div>
        <div>{notificationText}</div>
      </div>
    )
  }
}

export default NotificationView
